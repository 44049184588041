.ball-menu-container {
  background: transparent;
  overflow: visible;
  height: 100%;
  text-align: center; }

.ball-menu {
  position: relative;
  transform-origin: top left;
  overflow: visible; }

.menu-balls-wrapper {
  display: inline-block; }

.menu-balls {
  display: block;
  position: absolute;
  margin: 0 auto; }

.menu-ball {
  display: inline-block;
  text-align: center; }

.bubble-arrow-button-container {
  position: relative;
  width: 200px;
  height: 80px;
  display: flex;
  top: 90%;
  left: 100%; }

.bubble-arrow-button {
  display: block;
  margin: 2px;
  width: 50px;
  color: white;
  font-size: 26px;
  height: 50px;
  border-radius: 25px;
  border: 2px solid rgba(255, 255, 255, 0.48);
  background: rgba(255, 255, 255, 0.12);
  box-sizing: border-box;
  cursor: pointer;
  text-align: center; }
