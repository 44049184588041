.radial-progress {
  width: 50px;
  height: 50px;
  background-color: #0c0c0c;
  border-radius: 50%; }
  .radial-progress .circle .mask, .radial-progress .circle .fill, .radial-progress .circle .shadow {
    width: 50px;
    height: 50px;
    position: absolute;
    border-radius: 50%; }
  .radial-progress .circle .shadow {
    box-shadow: 0px 0px 0px rgba(2, 2, 2, 0.2) inset; }
  .radial-progress .circle .mask, .radial-progress .circle .fill {
    -webkit-backface-visibility: hidden;
    transition: -webkit-transform 0.1s;
    transition: -ms-transform 0.1s;
    transition: transform 0.1s;
    border-radius: 50%; }
  .radial-progress .circle .mask {
    clip: rect(0px, 50px, 50px, 25px); }
    .radial-progress .circle .mask .fill {
      clip: rect(0px, 25px, 50px, 0px);
      background-color: #7a7c86; }
  .radial-progress .inset {
    width: 40px;
    height: 40px;
    position: absolute;
    margin-left: 5px;
    margin-top: 5px;
    background-color: #0c0c0c;
    border-radius: 50%;
    box-shadow: 0px 0px 0px rgba(2, 2, 2, 0.2); }
    .radial-progress .inset .percentage {
      height: 22px;
      width: 25px;
      overflow: hidden;
      position: absolute;
      top: 9px;
      left: 7.5px;
      line-height: 1; }
      .radial-progress .inset .percentage i {
        cursor: pointer;
        user-select: none; }
      .radial-progress .inset .percentage .numbers {
        margin-top: -22px;
        transition: width 0.1s; }
        .radial-progress .inset .percentage .numbers span {
          width: 25px;
          display: inline-block;
          vertical-align: top;
          text-align: center;
          font-weight: 800;
          font-size: 22px;
          font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
          color: #97a71d; }
  .radial-progress[data-progress="0"] .circle .mask.full, .radial-progress[data-progress="0"] .circle .fill {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg); }
  .radial-progress[data-progress="0"] .circle .fill.fix {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg); }
  .radial-progress[data-progress="0"] .inset .percentage .numbers {
    width: 25px; }
  .radial-progress[data-progress="1"] .circle .mask.full, .radial-progress[data-progress="1"] .circle .fill {
    -webkit-transform: rotate(1.8deg);
    -ms-transform: rotate(1.8deg);
    transform: rotate(1.8deg); }
  .radial-progress[data-progress="1"] .circle .fill.fix {
    -webkit-transform: rotate(3.6deg);
    -ms-transform: rotate(3.6deg);
    transform: rotate(3.6deg); }
  .radial-progress[data-progress="1"] .inset .percentage .numbers {
    width: 50px; }
  .radial-progress[data-progress="2"] .circle .mask.full, .radial-progress[data-progress="2"] .circle .fill {
    -webkit-transform: rotate(3.6deg);
    -ms-transform: rotate(3.6deg);
    transform: rotate(3.6deg); }
  .radial-progress[data-progress="2"] .circle .fill.fix {
    -webkit-transform: rotate(7.2deg);
    -ms-transform: rotate(7.2deg);
    transform: rotate(7.2deg); }
  .radial-progress[data-progress="2"] .inset .percentage .numbers {
    width: 75px; }
  .radial-progress[data-progress="3"] .circle .mask.full, .radial-progress[data-progress="3"] .circle .fill {
    -webkit-transform: rotate(5.4deg);
    -ms-transform: rotate(5.4deg);
    transform: rotate(5.4deg); }
  .radial-progress[data-progress="3"] .circle .fill.fix {
    -webkit-transform: rotate(10.8deg);
    -ms-transform: rotate(10.8deg);
    transform: rotate(10.8deg); }
  .radial-progress[data-progress="3"] .inset .percentage .numbers {
    width: 100px; }
  .radial-progress[data-progress="4"] .circle .mask.full, .radial-progress[data-progress="4"] .circle .fill {
    -webkit-transform: rotate(7.2deg);
    -ms-transform: rotate(7.2deg);
    transform: rotate(7.2deg); }
  .radial-progress[data-progress="4"] .circle .fill.fix {
    -webkit-transform: rotate(14.4deg);
    -ms-transform: rotate(14.4deg);
    transform: rotate(14.4deg); }
  .radial-progress[data-progress="4"] .inset .percentage .numbers {
    width: 125px; }
  .radial-progress[data-progress="5"] .circle .mask.full, .radial-progress[data-progress="5"] .circle .fill {
    -webkit-transform: rotate(9deg);
    -ms-transform: rotate(9deg);
    transform: rotate(9deg); }
  .radial-progress[data-progress="5"] .circle .fill.fix {
    -webkit-transform: rotate(18deg);
    -ms-transform: rotate(18deg);
    transform: rotate(18deg); }
  .radial-progress[data-progress="5"] .inset .percentage .numbers {
    width: 150px; }
  .radial-progress[data-progress="6"] .circle .mask.full, .radial-progress[data-progress="6"] .circle .fill {
    -webkit-transform: rotate(10.8deg);
    -ms-transform: rotate(10.8deg);
    transform: rotate(10.8deg); }
  .radial-progress[data-progress="6"] .circle .fill.fix {
    -webkit-transform: rotate(21.6deg);
    -ms-transform: rotate(21.6deg);
    transform: rotate(21.6deg); }
  .radial-progress[data-progress="6"] .inset .percentage .numbers {
    width: 175px; }
  .radial-progress[data-progress="7"] .circle .mask.full, .radial-progress[data-progress="7"] .circle .fill {
    -webkit-transform: rotate(12.6deg);
    -ms-transform: rotate(12.6deg);
    transform: rotate(12.6deg); }
  .radial-progress[data-progress="7"] .circle .fill.fix {
    -webkit-transform: rotate(25.2deg);
    -ms-transform: rotate(25.2deg);
    transform: rotate(25.2deg); }
  .radial-progress[data-progress="7"] .inset .percentage .numbers {
    width: 200px; }
  .radial-progress[data-progress="8"] .circle .mask.full, .radial-progress[data-progress="8"] .circle .fill {
    -webkit-transform: rotate(14.4deg);
    -ms-transform: rotate(14.4deg);
    transform: rotate(14.4deg); }
  .radial-progress[data-progress="8"] .circle .fill.fix {
    -webkit-transform: rotate(28.8deg);
    -ms-transform: rotate(28.8deg);
    transform: rotate(28.8deg); }
  .radial-progress[data-progress="8"] .inset .percentage .numbers {
    width: 225px; }
  .radial-progress[data-progress="9"] .circle .mask.full, .radial-progress[data-progress="9"] .circle .fill {
    -webkit-transform: rotate(16.2deg);
    -ms-transform: rotate(16.2deg);
    transform: rotate(16.2deg); }
  .radial-progress[data-progress="9"] .circle .fill.fix {
    -webkit-transform: rotate(32.4deg);
    -ms-transform: rotate(32.4deg);
    transform: rotate(32.4deg); }
  .radial-progress[data-progress="9"] .inset .percentage .numbers {
    width: 250px; }
  .radial-progress[data-progress="10"] .circle .mask.full, .radial-progress[data-progress="10"] .circle .fill {
    -webkit-transform: rotate(18deg);
    -ms-transform: rotate(18deg);
    transform: rotate(18deg); }
  .radial-progress[data-progress="10"] .circle .fill.fix {
    -webkit-transform: rotate(36deg);
    -ms-transform: rotate(36deg);
    transform: rotate(36deg); }
  .radial-progress[data-progress="10"] .inset .percentage .numbers {
    width: 275px; }
  .radial-progress[data-progress="11"] .circle .mask.full, .radial-progress[data-progress="11"] .circle .fill {
    -webkit-transform: rotate(19.8deg);
    -ms-transform: rotate(19.8deg);
    transform: rotate(19.8deg); }
  .radial-progress[data-progress="11"] .circle .fill.fix {
    -webkit-transform: rotate(39.6deg);
    -ms-transform: rotate(39.6deg);
    transform: rotate(39.6deg); }
  .radial-progress[data-progress="11"] .inset .percentage .numbers {
    width: 300px; }
  .radial-progress[data-progress="12"] .circle .mask.full, .radial-progress[data-progress="12"] .circle .fill {
    -webkit-transform: rotate(21.6deg);
    -ms-transform: rotate(21.6deg);
    transform: rotate(21.6deg); }
  .radial-progress[data-progress="12"] .circle .fill.fix {
    -webkit-transform: rotate(43.2deg);
    -ms-transform: rotate(43.2deg);
    transform: rotate(43.2deg); }
  .radial-progress[data-progress="12"] .inset .percentage .numbers {
    width: 325px; }
  .radial-progress[data-progress="13"] .circle .mask.full, .radial-progress[data-progress="13"] .circle .fill {
    -webkit-transform: rotate(23.4deg);
    -ms-transform: rotate(23.4deg);
    transform: rotate(23.4deg); }
  .radial-progress[data-progress="13"] .circle .fill.fix {
    -webkit-transform: rotate(46.8deg);
    -ms-transform: rotate(46.8deg);
    transform: rotate(46.8deg); }
  .radial-progress[data-progress="13"] .inset .percentage .numbers {
    width: 350px; }
  .radial-progress[data-progress="14"] .circle .mask.full, .radial-progress[data-progress="14"] .circle .fill {
    -webkit-transform: rotate(25.2deg);
    -ms-transform: rotate(25.2deg);
    transform: rotate(25.2deg); }
  .radial-progress[data-progress="14"] .circle .fill.fix {
    -webkit-transform: rotate(50.4deg);
    -ms-transform: rotate(50.4deg);
    transform: rotate(50.4deg); }
  .radial-progress[data-progress="14"] .inset .percentage .numbers {
    width: 375px; }
  .radial-progress[data-progress="15"] .circle .mask.full, .radial-progress[data-progress="15"] .circle .fill {
    -webkit-transform: rotate(27deg);
    -ms-transform: rotate(27deg);
    transform: rotate(27deg); }
  .radial-progress[data-progress="15"] .circle .fill.fix {
    -webkit-transform: rotate(54deg);
    -ms-transform: rotate(54deg);
    transform: rotate(54deg); }
  .radial-progress[data-progress="15"] .inset .percentage .numbers {
    width: 400px; }
  .radial-progress[data-progress="16"] .circle .mask.full, .radial-progress[data-progress="16"] .circle .fill {
    -webkit-transform: rotate(28.8deg);
    -ms-transform: rotate(28.8deg);
    transform: rotate(28.8deg); }
  .radial-progress[data-progress="16"] .circle .fill.fix {
    -webkit-transform: rotate(57.6deg);
    -ms-transform: rotate(57.6deg);
    transform: rotate(57.6deg); }
  .radial-progress[data-progress="16"] .inset .percentage .numbers {
    width: 425px; }
  .radial-progress[data-progress="17"] .circle .mask.full, .radial-progress[data-progress="17"] .circle .fill {
    -webkit-transform: rotate(30.6deg);
    -ms-transform: rotate(30.6deg);
    transform: rotate(30.6deg); }
  .radial-progress[data-progress="17"] .circle .fill.fix {
    -webkit-transform: rotate(61.2deg);
    -ms-transform: rotate(61.2deg);
    transform: rotate(61.2deg); }
  .radial-progress[data-progress="17"] .inset .percentage .numbers {
    width: 450px; }
  .radial-progress[data-progress="18"] .circle .mask.full, .radial-progress[data-progress="18"] .circle .fill {
    -webkit-transform: rotate(32.4deg);
    -ms-transform: rotate(32.4deg);
    transform: rotate(32.4deg); }
  .radial-progress[data-progress="18"] .circle .fill.fix {
    -webkit-transform: rotate(64.8deg);
    -ms-transform: rotate(64.8deg);
    transform: rotate(64.8deg); }
  .radial-progress[data-progress="18"] .inset .percentage .numbers {
    width: 475px; }
  .radial-progress[data-progress="19"] .circle .mask.full, .radial-progress[data-progress="19"] .circle .fill {
    -webkit-transform: rotate(34.2deg);
    -ms-transform: rotate(34.2deg);
    transform: rotate(34.2deg); }
  .radial-progress[data-progress="19"] .circle .fill.fix {
    -webkit-transform: rotate(68.4deg);
    -ms-transform: rotate(68.4deg);
    transform: rotate(68.4deg); }
  .radial-progress[data-progress="19"] .inset .percentage .numbers {
    width: 500px; }
  .radial-progress[data-progress="20"] .circle .mask.full, .radial-progress[data-progress="20"] .circle .fill {
    -webkit-transform: rotate(36deg);
    -ms-transform: rotate(36deg);
    transform: rotate(36deg); }
  .radial-progress[data-progress="20"] .circle .fill.fix {
    -webkit-transform: rotate(72deg);
    -ms-transform: rotate(72deg);
    transform: rotate(72deg); }
  .radial-progress[data-progress="20"] .inset .percentage .numbers {
    width: 525px; }
  .radial-progress[data-progress="21"] .circle .mask.full, .radial-progress[data-progress="21"] .circle .fill {
    -webkit-transform: rotate(37.8deg);
    -ms-transform: rotate(37.8deg);
    transform: rotate(37.8deg); }
  .radial-progress[data-progress="21"] .circle .fill.fix {
    -webkit-transform: rotate(75.6deg);
    -ms-transform: rotate(75.6deg);
    transform: rotate(75.6deg); }
  .radial-progress[data-progress="21"] .inset .percentage .numbers {
    width: 550px; }
  .radial-progress[data-progress="22"] .circle .mask.full, .radial-progress[data-progress="22"] .circle .fill {
    -webkit-transform: rotate(39.6deg);
    -ms-transform: rotate(39.6deg);
    transform: rotate(39.6deg); }
  .radial-progress[data-progress="22"] .circle .fill.fix {
    -webkit-transform: rotate(79.2deg);
    -ms-transform: rotate(79.2deg);
    transform: rotate(79.2deg); }
  .radial-progress[data-progress="22"] .inset .percentage .numbers {
    width: 575px; }
  .radial-progress[data-progress="23"] .circle .mask.full, .radial-progress[data-progress="23"] .circle .fill {
    -webkit-transform: rotate(41.4deg);
    -ms-transform: rotate(41.4deg);
    transform: rotate(41.4deg); }
  .radial-progress[data-progress="23"] .circle .fill.fix {
    -webkit-transform: rotate(82.8deg);
    -ms-transform: rotate(82.8deg);
    transform: rotate(82.8deg); }
  .radial-progress[data-progress="23"] .inset .percentage .numbers {
    width: 600px; }
  .radial-progress[data-progress="24"] .circle .mask.full, .radial-progress[data-progress="24"] .circle .fill {
    -webkit-transform: rotate(43.2deg);
    -ms-transform: rotate(43.2deg);
    transform: rotate(43.2deg); }
  .radial-progress[data-progress="24"] .circle .fill.fix {
    -webkit-transform: rotate(86.4deg);
    -ms-transform: rotate(86.4deg);
    transform: rotate(86.4deg); }
  .radial-progress[data-progress="24"] .inset .percentage .numbers {
    width: 625px; }
  .radial-progress[data-progress="25"] .circle .mask.full, .radial-progress[data-progress="25"] .circle .fill {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg); }
  .radial-progress[data-progress="25"] .circle .fill.fix {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg); }
  .radial-progress[data-progress="25"] .inset .percentage .numbers {
    width: 650px; }
  .radial-progress[data-progress="26"] .circle .mask.full, .radial-progress[data-progress="26"] .circle .fill {
    -webkit-transform: rotate(46.8deg);
    -ms-transform: rotate(46.8deg);
    transform: rotate(46.8deg); }
  .radial-progress[data-progress="26"] .circle .fill.fix {
    -webkit-transform: rotate(93.6deg);
    -ms-transform: rotate(93.6deg);
    transform: rotate(93.6deg); }
  .radial-progress[data-progress="26"] .inset .percentage .numbers {
    width: 675px; }
  .radial-progress[data-progress="27"] .circle .mask.full, .radial-progress[data-progress="27"] .circle .fill {
    -webkit-transform: rotate(48.6deg);
    -ms-transform: rotate(48.6deg);
    transform: rotate(48.6deg); }
  .radial-progress[data-progress="27"] .circle .fill.fix {
    -webkit-transform: rotate(97.2deg);
    -ms-transform: rotate(97.2deg);
    transform: rotate(97.2deg); }
  .radial-progress[data-progress="27"] .inset .percentage .numbers {
    width: 700px; }
  .radial-progress[data-progress="28"] .circle .mask.full, .radial-progress[data-progress="28"] .circle .fill {
    -webkit-transform: rotate(50.4deg);
    -ms-transform: rotate(50.4deg);
    transform: rotate(50.4deg); }
  .radial-progress[data-progress="28"] .circle .fill.fix {
    -webkit-transform: rotate(100.8deg);
    -ms-transform: rotate(100.8deg);
    transform: rotate(100.8deg); }
  .radial-progress[data-progress="28"] .inset .percentage .numbers {
    width: 725px; }
  .radial-progress[data-progress="29"] .circle .mask.full, .radial-progress[data-progress="29"] .circle .fill {
    -webkit-transform: rotate(52.2deg);
    -ms-transform: rotate(52.2deg);
    transform: rotate(52.2deg); }
  .radial-progress[data-progress="29"] .circle .fill.fix {
    -webkit-transform: rotate(104.4deg);
    -ms-transform: rotate(104.4deg);
    transform: rotate(104.4deg); }
  .radial-progress[data-progress="29"] .inset .percentage .numbers {
    width: 750px; }
  .radial-progress[data-progress="30"] .circle .mask.full, .radial-progress[data-progress="30"] .circle .fill {
    -webkit-transform: rotate(54deg);
    -ms-transform: rotate(54deg);
    transform: rotate(54deg); }
  .radial-progress[data-progress="30"] .circle .fill.fix {
    -webkit-transform: rotate(108deg);
    -ms-transform: rotate(108deg);
    transform: rotate(108deg); }
  .radial-progress[data-progress="30"] .inset .percentage .numbers {
    width: 775px; }
  .radial-progress[data-progress="31"] .circle .mask.full, .radial-progress[data-progress="31"] .circle .fill {
    -webkit-transform: rotate(55.8deg);
    -ms-transform: rotate(55.8deg);
    transform: rotate(55.8deg); }
  .radial-progress[data-progress="31"] .circle .fill.fix {
    -webkit-transform: rotate(111.6deg);
    -ms-transform: rotate(111.6deg);
    transform: rotate(111.6deg); }
  .radial-progress[data-progress="31"] .inset .percentage .numbers {
    width: 800px; }
  .radial-progress[data-progress="32"] .circle .mask.full, .radial-progress[data-progress="32"] .circle .fill {
    -webkit-transform: rotate(57.6deg);
    -ms-transform: rotate(57.6deg);
    transform: rotate(57.6deg); }
  .radial-progress[data-progress="32"] .circle .fill.fix {
    -webkit-transform: rotate(115.2deg);
    -ms-transform: rotate(115.2deg);
    transform: rotate(115.2deg); }
  .radial-progress[data-progress="32"] .inset .percentage .numbers {
    width: 825px; }
  .radial-progress[data-progress="33"] .circle .mask.full, .radial-progress[data-progress="33"] .circle .fill {
    -webkit-transform: rotate(59.4deg);
    -ms-transform: rotate(59.4deg);
    transform: rotate(59.4deg); }
  .radial-progress[data-progress="33"] .circle .fill.fix {
    -webkit-transform: rotate(118.8deg);
    -ms-transform: rotate(118.8deg);
    transform: rotate(118.8deg); }
  .radial-progress[data-progress="33"] .inset .percentage .numbers {
    width: 850px; }
  .radial-progress[data-progress="34"] .circle .mask.full, .radial-progress[data-progress="34"] .circle .fill {
    -webkit-transform: rotate(61.2deg);
    -ms-transform: rotate(61.2deg);
    transform: rotate(61.2deg); }
  .radial-progress[data-progress="34"] .circle .fill.fix {
    -webkit-transform: rotate(122.4deg);
    -ms-transform: rotate(122.4deg);
    transform: rotate(122.4deg); }
  .radial-progress[data-progress="34"] .inset .percentage .numbers {
    width: 875px; }
  .radial-progress[data-progress="35"] .circle .mask.full, .radial-progress[data-progress="35"] .circle .fill {
    -webkit-transform: rotate(63deg);
    -ms-transform: rotate(63deg);
    transform: rotate(63deg); }
  .radial-progress[data-progress="35"] .circle .fill.fix {
    -webkit-transform: rotate(126deg);
    -ms-transform: rotate(126deg);
    transform: rotate(126deg); }
  .radial-progress[data-progress="35"] .inset .percentage .numbers {
    width: 900px; }
  .radial-progress[data-progress="36"] .circle .mask.full, .radial-progress[data-progress="36"] .circle .fill {
    -webkit-transform: rotate(64.8deg);
    -ms-transform: rotate(64.8deg);
    transform: rotate(64.8deg); }
  .radial-progress[data-progress="36"] .circle .fill.fix {
    -webkit-transform: rotate(129.6deg);
    -ms-transform: rotate(129.6deg);
    transform: rotate(129.6deg); }
  .radial-progress[data-progress="36"] .inset .percentage .numbers {
    width: 925px; }
  .radial-progress[data-progress="37"] .circle .mask.full, .radial-progress[data-progress="37"] .circle .fill {
    -webkit-transform: rotate(66.6deg);
    -ms-transform: rotate(66.6deg);
    transform: rotate(66.6deg); }
  .radial-progress[data-progress="37"] .circle .fill.fix {
    -webkit-transform: rotate(133.2deg);
    -ms-transform: rotate(133.2deg);
    transform: rotate(133.2deg); }
  .radial-progress[data-progress="37"] .inset .percentage .numbers {
    width: 950px; }
  .radial-progress[data-progress="38"] .circle .mask.full, .radial-progress[data-progress="38"] .circle .fill {
    -webkit-transform: rotate(68.4deg);
    -ms-transform: rotate(68.4deg);
    transform: rotate(68.4deg); }
  .radial-progress[data-progress="38"] .circle .fill.fix {
    -webkit-transform: rotate(136.8deg);
    -ms-transform: rotate(136.8deg);
    transform: rotate(136.8deg); }
  .radial-progress[data-progress="38"] .inset .percentage .numbers {
    width: 975px; }
  .radial-progress[data-progress="39"] .circle .mask.full, .radial-progress[data-progress="39"] .circle .fill {
    -webkit-transform: rotate(70.2deg);
    -ms-transform: rotate(70.2deg);
    transform: rotate(70.2deg); }
  .radial-progress[data-progress="39"] .circle .fill.fix {
    -webkit-transform: rotate(140.4deg);
    -ms-transform: rotate(140.4deg);
    transform: rotate(140.4deg); }
  .radial-progress[data-progress="39"] .inset .percentage .numbers {
    width: 1000px; }
  .radial-progress[data-progress="40"] .circle .mask.full, .radial-progress[data-progress="40"] .circle .fill {
    -webkit-transform: rotate(72deg);
    -ms-transform: rotate(72deg);
    transform: rotate(72deg); }
  .radial-progress[data-progress="40"] .circle .fill.fix {
    -webkit-transform: rotate(144deg);
    -ms-transform: rotate(144deg);
    transform: rotate(144deg); }
  .radial-progress[data-progress="40"] .inset .percentage .numbers {
    width: 1025px; }
  .radial-progress[data-progress="41"] .circle .mask.full, .radial-progress[data-progress="41"] .circle .fill {
    -webkit-transform: rotate(73.8deg);
    -ms-transform: rotate(73.8deg);
    transform: rotate(73.8deg); }
  .radial-progress[data-progress="41"] .circle .fill.fix {
    -webkit-transform: rotate(147.6deg);
    -ms-transform: rotate(147.6deg);
    transform: rotate(147.6deg); }
  .radial-progress[data-progress="41"] .inset .percentage .numbers {
    width: 1050px; }
  .radial-progress[data-progress="42"] .circle .mask.full, .radial-progress[data-progress="42"] .circle .fill {
    -webkit-transform: rotate(75.6deg);
    -ms-transform: rotate(75.6deg);
    transform: rotate(75.6deg); }
  .radial-progress[data-progress="42"] .circle .fill.fix {
    -webkit-transform: rotate(151.2deg);
    -ms-transform: rotate(151.2deg);
    transform: rotate(151.2deg); }
  .radial-progress[data-progress="42"] .inset .percentage .numbers {
    width: 1075px; }
  .radial-progress[data-progress="43"] .circle .mask.full, .radial-progress[data-progress="43"] .circle .fill {
    -webkit-transform: rotate(77.4deg);
    -ms-transform: rotate(77.4deg);
    transform: rotate(77.4deg); }
  .radial-progress[data-progress="43"] .circle .fill.fix {
    -webkit-transform: rotate(154.8deg);
    -ms-transform: rotate(154.8deg);
    transform: rotate(154.8deg); }
  .radial-progress[data-progress="43"] .inset .percentage .numbers {
    width: 1100px; }
  .radial-progress[data-progress="44"] .circle .mask.full, .radial-progress[data-progress="44"] .circle .fill {
    -webkit-transform: rotate(79.2deg);
    -ms-transform: rotate(79.2deg);
    transform: rotate(79.2deg); }
  .radial-progress[data-progress="44"] .circle .fill.fix {
    -webkit-transform: rotate(158.4deg);
    -ms-transform: rotate(158.4deg);
    transform: rotate(158.4deg); }
  .radial-progress[data-progress="44"] .inset .percentage .numbers {
    width: 1125px; }
  .radial-progress[data-progress="45"] .circle .mask.full, .radial-progress[data-progress="45"] .circle .fill {
    -webkit-transform: rotate(81deg);
    -ms-transform: rotate(81deg);
    transform: rotate(81deg); }
  .radial-progress[data-progress="45"] .circle .fill.fix {
    -webkit-transform: rotate(162deg);
    -ms-transform: rotate(162deg);
    transform: rotate(162deg); }
  .radial-progress[data-progress="45"] .inset .percentage .numbers {
    width: 1150px; }
  .radial-progress[data-progress="46"] .circle .mask.full, .radial-progress[data-progress="46"] .circle .fill {
    -webkit-transform: rotate(82.8deg);
    -ms-transform: rotate(82.8deg);
    transform: rotate(82.8deg); }
  .radial-progress[data-progress="46"] .circle .fill.fix {
    -webkit-transform: rotate(165.6deg);
    -ms-transform: rotate(165.6deg);
    transform: rotate(165.6deg); }
  .radial-progress[data-progress="46"] .inset .percentage .numbers {
    width: 1175px; }
  .radial-progress[data-progress="47"] .circle .mask.full, .radial-progress[data-progress="47"] .circle .fill {
    -webkit-transform: rotate(84.6deg);
    -ms-transform: rotate(84.6deg);
    transform: rotate(84.6deg); }
  .radial-progress[data-progress="47"] .circle .fill.fix {
    -webkit-transform: rotate(169.2deg);
    -ms-transform: rotate(169.2deg);
    transform: rotate(169.2deg); }
  .radial-progress[data-progress="47"] .inset .percentage .numbers {
    width: 1200px; }
  .radial-progress[data-progress="48"] .circle .mask.full, .radial-progress[data-progress="48"] .circle .fill {
    -webkit-transform: rotate(86.4deg);
    -ms-transform: rotate(86.4deg);
    transform: rotate(86.4deg); }
  .radial-progress[data-progress="48"] .circle .fill.fix {
    -webkit-transform: rotate(172.8deg);
    -ms-transform: rotate(172.8deg);
    transform: rotate(172.8deg); }
  .radial-progress[data-progress="48"] .inset .percentage .numbers {
    width: 1225px; }
  .radial-progress[data-progress="49"] .circle .mask.full, .radial-progress[data-progress="49"] .circle .fill {
    -webkit-transform: rotate(88.2deg);
    -ms-transform: rotate(88.2deg);
    transform: rotate(88.2deg); }
  .radial-progress[data-progress="49"] .circle .fill.fix {
    -webkit-transform: rotate(176.4deg);
    -ms-transform: rotate(176.4deg);
    transform: rotate(176.4deg); }
  .radial-progress[data-progress="49"] .inset .percentage .numbers {
    width: 1250px; }
  .radial-progress[data-progress="50"] .circle .mask.full, .radial-progress[data-progress="50"] .circle .fill {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg); }
  .radial-progress[data-progress="50"] .circle .fill.fix {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg); }
  .radial-progress[data-progress="50"] .inset .percentage .numbers {
    width: 1275px; }
  .radial-progress[data-progress="51"] .circle .mask.full, .radial-progress[data-progress="51"] .circle .fill {
    -webkit-transform: rotate(91.8deg);
    -ms-transform: rotate(91.8deg);
    transform: rotate(91.8deg); }
  .radial-progress[data-progress="51"] .circle .fill.fix {
    -webkit-transform: rotate(183.6deg);
    -ms-transform: rotate(183.6deg);
    transform: rotate(183.6deg); }
  .radial-progress[data-progress="51"] .inset .percentage .numbers {
    width: 1300px; }
  .radial-progress[data-progress="52"] .circle .mask.full, .radial-progress[data-progress="52"] .circle .fill {
    -webkit-transform: rotate(93.6deg);
    -ms-transform: rotate(93.6deg);
    transform: rotate(93.6deg); }
  .radial-progress[data-progress="52"] .circle .fill.fix {
    -webkit-transform: rotate(187.2deg);
    -ms-transform: rotate(187.2deg);
    transform: rotate(187.2deg); }
  .radial-progress[data-progress="52"] .inset .percentage .numbers {
    width: 1325px; }
  .radial-progress[data-progress="53"] .circle .mask.full, .radial-progress[data-progress="53"] .circle .fill {
    -webkit-transform: rotate(95.4deg);
    -ms-transform: rotate(95.4deg);
    transform: rotate(95.4deg); }
  .radial-progress[data-progress="53"] .circle .fill.fix {
    -webkit-transform: rotate(190.8deg);
    -ms-transform: rotate(190.8deg);
    transform: rotate(190.8deg); }
  .radial-progress[data-progress="53"] .inset .percentage .numbers {
    width: 1350px; }
  .radial-progress[data-progress="54"] .circle .mask.full, .radial-progress[data-progress="54"] .circle .fill {
    -webkit-transform: rotate(97.2deg);
    -ms-transform: rotate(97.2deg);
    transform: rotate(97.2deg); }
  .radial-progress[data-progress="54"] .circle .fill.fix {
    -webkit-transform: rotate(194.4deg);
    -ms-transform: rotate(194.4deg);
    transform: rotate(194.4deg); }
  .radial-progress[data-progress="54"] .inset .percentage .numbers {
    width: 1375px; }
  .radial-progress[data-progress="55"] .circle .mask.full, .radial-progress[data-progress="55"] .circle .fill {
    -webkit-transform: rotate(99deg);
    -ms-transform: rotate(99deg);
    transform: rotate(99deg); }
  .radial-progress[data-progress="55"] .circle .fill.fix {
    -webkit-transform: rotate(198deg);
    -ms-transform: rotate(198deg);
    transform: rotate(198deg); }
  .radial-progress[data-progress="55"] .inset .percentage .numbers {
    width: 1400px; }
  .radial-progress[data-progress="56"] .circle .mask.full, .radial-progress[data-progress="56"] .circle .fill {
    -webkit-transform: rotate(100.8deg);
    -ms-transform: rotate(100.8deg);
    transform: rotate(100.8deg); }
  .radial-progress[data-progress="56"] .circle .fill.fix {
    -webkit-transform: rotate(201.6deg);
    -ms-transform: rotate(201.6deg);
    transform: rotate(201.6deg); }
  .radial-progress[data-progress="56"] .inset .percentage .numbers {
    width: 1425px; }
  .radial-progress[data-progress="57"] .circle .mask.full, .radial-progress[data-progress="57"] .circle .fill {
    -webkit-transform: rotate(102.6deg);
    -ms-transform: rotate(102.6deg);
    transform: rotate(102.6deg); }
  .radial-progress[data-progress="57"] .circle .fill.fix {
    -webkit-transform: rotate(205.2deg);
    -ms-transform: rotate(205.2deg);
    transform: rotate(205.2deg); }
  .radial-progress[data-progress="57"] .inset .percentage .numbers {
    width: 1450px; }
  .radial-progress[data-progress="58"] .circle .mask.full, .radial-progress[data-progress="58"] .circle .fill {
    -webkit-transform: rotate(104.4deg);
    -ms-transform: rotate(104.4deg);
    transform: rotate(104.4deg); }
  .radial-progress[data-progress="58"] .circle .fill.fix {
    -webkit-transform: rotate(208.8deg);
    -ms-transform: rotate(208.8deg);
    transform: rotate(208.8deg); }
  .radial-progress[data-progress="58"] .inset .percentage .numbers {
    width: 1475px; }
  .radial-progress[data-progress="59"] .circle .mask.full, .radial-progress[data-progress="59"] .circle .fill {
    -webkit-transform: rotate(106.2deg);
    -ms-transform: rotate(106.2deg);
    transform: rotate(106.2deg); }
  .radial-progress[data-progress="59"] .circle .fill.fix {
    -webkit-transform: rotate(212.4deg);
    -ms-transform: rotate(212.4deg);
    transform: rotate(212.4deg); }
  .radial-progress[data-progress="59"] .inset .percentage .numbers {
    width: 1500px; }
  .radial-progress[data-progress="60"] .circle .mask.full, .radial-progress[data-progress="60"] .circle .fill {
    -webkit-transform: rotate(108deg);
    -ms-transform: rotate(108deg);
    transform: rotate(108deg); }
  .radial-progress[data-progress="60"] .circle .fill.fix {
    -webkit-transform: rotate(216deg);
    -ms-transform: rotate(216deg);
    transform: rotate(216deg); }
  .radial-progress[data-progress="60"] .inset .percentage .numbers {
    width: 1525px; }
  .radial-progress[data-progress="61"] .circle .mask.full, .radial-progress[data-progress="61"] .circle .fill {
    -webkit-transform: rotate(109.8deg);
    -ms-transform: rotate(109.8deg);
    transform: rotate(109.8deg); }
  .radial-progress[data-progress="61"] .circle .fill.fix {
    -webkit-transform: rotate(219.6deg);
    -ms-transform: rotate(219.6deg);
    transform: rotate(219.6deg); }
  .radial-progress[data-progress="61"] .inset .percentage .numbers {
    width: 1550px; }
  .radial-progress[data-progress="62"] .circle .mask.full, .radial-progress[data-progress="62"] .circle .fill {
    -webkit-transform: rotate(111.6deg);
    -ms-transform: rotate(111.6deg);
    transform: rotate(111.6deg); }
  .radial-progress[data-progress="62"] .circle .fill.fix {
    -webkit-transform: rotate(223.2deg);
    -ms-transform: rotate(223.2deg);
    transform: rotate(223.2deg); }
  .radial-progress[data-progress="62"] .inset .percentage .numbers {
    width: 1575px; }
  .radial-progress[data-progress="63"] .circle .mask.full, .radial-progress[data-progress="63"] .circle .fill {
    -webkit-transform: rotate(113.4deg);
    -ms-transform: rotate(113.4deg);
    transform: rotate(113.4deg); }
  .radial-progress[data-progress="63"] .circle .fill.fix {
    -webkit-transform: rotate(226.8deg);
    -ms-transform: rotate(226.8deg);
    transform: rotate(226.8deg); }
  .radial-progress[data-progress="63"] .inset .percentage .numbers {
    width: 1600px; }
  .radial-progress[data-progress="64"] .circle .mask.full, .radial-progress[data-progress="64"] .circle .fill {
    -webkit-transform: rotate(115.2deg);
    -ms-transform: rotate(115.2deg);
    transform: rotate(115.2deg); }
  .radial-progress[data-progress="64"] .circle .fill.fix {
    -webkit-transform: rotate(230.4deg);
    -ms-transform: rotate(230.4deg);
    transform: rotate(230.4deg); }
  .radial-progress[data-progress="64"] .inset .percentage .numbers {
    width: 1625px; }
  .radial-progress[data-progress="65"] .circle .mask.full, .radial-progress[data-progress="65"] .circle .fill {
    -webkit-transform: rotate(117deg);
    -ms-transform: rotate(117deg);
    transform: rotate(117deg); }
  .radial-progress[data-progress="65"] .circle .fill.fix {
    -webkit-transform: rotate(234deg);
    -ms-transform: rotate(234deg);
    transform: rotate(234deg); }
  .radial-progress[data-progress="65"] .inset .percentage .numbers {
    width: 1650px; }
  .radial-progress[data-progress="66"] .circle .mask.full, .radial-progress[data-progress="66"] .circle .fill {
    -webkit-transform: rotate(118.8deg);
    -ms-transform: rotate(118.8deg);
    transform: rotate(118.8deg); }
  .radial-progress[data-progress="66"] .circle .fill.fix {
    -webkit-transform: rotate(237.6deg);
    -ms-transform: rotate(237.6deg);
    transform: rotate(237.6deg); }
  .radial-progress[data-progress="66"] .inset .percentage .numbers {
    width: 1675px; }
  .radial-progress[data-progress="67"] .circle .mask.full, .radial-progress[data-progress="67"] .circle .fill {
    -webkit-transform: rotate(120.6deg);
    -ms-transform: rotate(120.6deg);
    transform: rotate(120.6deg); }
  .radial-progress[data-progress="67"] .circle .fill.fix {
    -webkit-transform: rotate(241.2deg);
    -ms-transform: rotate(241.2deg);
    transform: rotate(241.2deg); }
  .radial-progress[data-progress="67"] .inset .percentage .numbers {
    width: 1700px; }
  .radial-progress[data-progress="68"] .circle .mask.full, .radial-progress[data-progress="68"] .circle .fill {
    -webkit-transform: rotate(122.4deg);
    -ms-transform: rotate(122.4deg);
    transform: rotate(122.4deg); }
  .radial-progress[data-progress="68"] .circle .fill.fix {
    -webkit-transform: rotate(244.8deg);
    -ms-transform: rotate(244.8deg);
    transform: rotate(244.8deg); }
  .radial-progress[data-progress="68"] .inset .percentage .numbers {
    width: 1725px; }
  .radial-progress[data-progress="69"] .circle .mask.full, .radial-progress[data-progress="69"] .circle .fill {
    -webkit-transform: rotate(124.2deg);
    -ms-transform: rotate(124.2deg);
    transform: rotate(124.2deg); }
  .radial-progress[data-progress="69"] .circle .fill.fix {
    -webkit-transform: rotate(248.4deg);
    -ms-transform: rotate(248.4deg);
    transform: rotate(248.4deg); }
  .radial-progress[data-progress="69"] .inset .percentage .numbers {
    width: 1750px; }
  .radial-progress[data-progress="70"] .circle .mask.full, .radial-progress[data-progress="70"] .circle .fill {
    -webkit-transform: rotate(126deg);
    -ms-transform: rotate(126deg);
    transform: rotate(126deg); }
  .radial-progress[data-progress="70"] .circle .fill.fix {
    -webkit-transform: rotate(252deg);
    -ms-transform: rotate(252deg);
    transform: rotate(252deg); }
  .radial-progress[data-progress="70"] .inset .percentage .numbers {
    width: 1775px; }
  .radial-progress[data-progress="71"] .circle .mask.full, .radial-progress[data-progress="71"] .circle .fill {
    -webkit-transform: rotate(127.8deg);
    -ms-transform: rotate(127.8deg);
    transform: rotate(127.8deg); }
  .radial-progress[data-progress="71"] .circle .fill.fix {
    -webkit-transform: rotate(255.6deg);
    -ms-transform: rotate(255.6deg);
    transform: rotate(255.6deg); }
  .radial-progress[data-progress="71"] .inset .percentage .numbers {
    width: 1800px; }
  .radial-progress[data-progress="72"] .circle .mask.full, .radial-progress[data-progress="72"] .circle .fill {
    -webkit-transform: rotate(129.6deg);
    -ms-transform: rotate(129.6deg);
    transform: rotate(129.6deg); }
  .radial-progress[data-progress="72"] .circle .fill.fix {
    -webkit-transform: rotate(259.2deg);
    -ms-transform: rotate(259.2deg);
    transform: rotate(259.2deg); }
  .radial-progress[data-progress="72"] .inset .percentage .numbers {
    width: 1825px; }
  .radial-progress[data-progress="73"] .circle .mask.full, .radial-progress[data-progress="73"] .circle .fill {
    -webkit-transform: rotate(131.4deg);
    -ms-transform: rotate(131.4deg);
    transform: rotate(131.4deg); }
  .radial-progress[data-progress="73"] .circle .fill.fix {
    -webkit-transform: rotate(262.8deg);
    -ms-transform: rotate(262.8deg);
    transform: rotate(262.8deg); }
  .radial-progress[data-progress="73"] .inset .percentage .numbers {
    width: 1850px; }
  .radial-progress[data-progress="74"] .circle .mask.full, .radial-progress[data-progress="74"] .circle .fill {
    -webkit-transform: rotate(133.2deg);
    -ms-transform: rotate(133.2deg);
    transform: rotate(133.2deg); }
  .radial-progress[data-progress="74"] .circle .fill.fix {
    -webkit-transform: rotate(266.4deg);
    -ms-transform: rotate(266.4deg);
    transform: rotate(266.4deg); }
  .radial-progress[data-progress="74"] .inset .percentage .numbers {
    width: 1875px; }
  .radial-progress[data-progress="75"] .circle .mask.full, .radial-progress[data-progress="75"] .circle .fill {
    -webkit-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    transform: rotate(135deg); }
  .radial-progress[data-progress="75"] .circle .fill.fix {
    -webkit-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg); }
  .radial-progress[data-progress="75"] .inset .percentage .numbers {
    width: 1900px; }
  .radial-progress[data-progress="76"] .circle .mask.full, .radial-progress[data-progress="76"] .circle .fill {
    -webkit-transform: rotate(136.8deg);
    -ms-transform: rotate(136.8deg);
    transform: rotate(136.8deg); }
  .radial-progress[data-progress="76"] .circle .fill.fix {
    -webkit-transform: rotate(273.6deg);
    -ms-transform: rotate(273.6deg);
    transform: rotate(273.6deg); }
  .radial-progress[data-progress="76"] .inset .percentage .numbers {
    width: 1925px; }
  .radial-progress[data-progress="77"] .circle .mask.full, .radial-progress[data-progress="77"] .circle .fill {
    -webkit-transform: rotate(138.6deg);
    -ms-transform: rotate(138.6deg);
    transform: rotate(138.6deg); }
  .radial-progress[data-progress="77"] .circle .fill.fix {
    -webkit-transform: rotate(277.2deg);
    -ms-transform: rotate(277.2deg);
    transform: rotate(277.2deg); }
  .radial-progress[data-progress="77"] .inset .percentage .numbers {
    width: 1950px; }
  .radial-progress[data-progress="78"] .circle .mask.full, .radial-progress[data-progress="78"] .circle .fill {
    -webkit-transform: rotate(140.4deg);
    -ms-transform: rotate(140.4deg);
    transform: rotate(140.4deg); }
  .radial-progress[data-progress="78"] .circle .fill.fix {
    -webkit-transform: rotate(280.8deg);
    -ms-transform: rotate(280.8deg);
    transform: rotate(280.8deg); }
  .radial-progress[data-progress="78"] .inset .percentage .numbers {
    width: 1975px; }
  .radial-progress[data-progress="79"] .circle .mask.full, .radial-progress[data-progress="79"] .circle .fill {
    -webkit-transform: rotate(142.2deg);
    -ms-transform: rotate(142.2deg);
    transform: rotate(142.2deg); }
  .radial-progress[data-progress="79"] .circle .fill.fix {
    -webkit-transform: rotate(284.4deg);
    -ms-transform: rotate(284.4deg);
    transform: rotate(284.4deg); }
  .radial-progress[data-progress="79"] .inset .percentage .numbers {
    width: 2000px; }
  .radial-progress[data-progress="80"] .circle .mask.full, .radial-progress[data-progress="80"] .circle .fill {
    -webkit-transform: rotate(144deg);
    -ms-transform: rotate(144deg);
    transform: rotate(144deg); }
  .radial-progress[data-progress="80"] .circle .fill.fix {
    -webkit-transform: rotate(288deg);
    -ms-transform: rotate(288deg);
    transform: rotate(288deg); }
  .radial-progress[data-progress="80"] .inset .percentage .numbers {
    width: 2025px; }
  .radial-progress[data-progress="81"] .circle .mask.full, .radial-progress[data-progress="81"] .circle .fill {
    -webkit-transform: rotate(145.8deg);
    -ms-transform: rotate(145.8deg);
    transform: rotate(145.8deg); }
  .radial-progress[data-progress="81"] .circle .fill.fix {
    -webkit-transform: rotate(291.6deg);
    -ms-transform: rotate(291.6deg);
    transform: rotate(291.6deg); }
  .radial-progress[data-progress="81"] .inset .percentage .numbers {
    width: 2050px; }
  .radial-progress[data-progress="82"] .circle .mask.full, .radial-progress[data-progress="82"] .circle .fill {
    -webkit-transform: rotate(147.6deg);
    -ms-transform: rotate(147.6deg);
    transform: rotate(147.6deg); }
  .radial-progress[data-progress="82"] .circle .fill.fix {
    -webkit-transform: rotate(295.2deg);
    -ms-transform: rotate(295.2deg);
    transform: rotate(295.2deg); }
  .radial-progress[data-progress="82"] .inset .percentage .numbers {
    width: 2075px; }
  .radial-progress[data-progress="83"] .circle .mask.full, .radial-progress[data-progress="83"] .circle .fill {
    -webkit-transform: rotate(149.4deg);
    -ms-transform: rotate(149.4deg);
    transform: rotate(149.4deg); }
  .radial-progress[data-progress="83"] .circle .fill.fix {
    -webkit-transform: rotate(298.8deg);
    -ms-transform: rotate(298.8deg);
    transform: rotate(298.8deg); }
  .radial-progress[data-progress="83"] .inset .percentage .numbers {
    width: 2100px; }
  .radial-progress[data-progress="84"] .circle .mask.full, .radial-progress[data-progress="84"] .circle .fill {
    -webkit-transform: rotate(151.2deg);
    -ms-transform: rotate(151.2deg);
    transform: rotate(151.2deg); }
  .radial-progress[data-progress="84"] .circle .fill.fix {
    -webkit-transform: rotate(302.4deg);
    -ms-transform: rotate(302.4deg);
    transform: rotate(302.4deg); }
  .radial-progress[data-progress="84"] .inset .percentage .numbers {
    width: 2125px; }
  .radial-progress[data-progress="85"] .circle .mask.full, .radial-progress[data-progress="85"] .circle .fill {
    -webkit-transform: rotate(153deg);
    -ms-transform: rotate(153deg);
    transform: rotate(153deg); }
  .radial-progress[data-progress="85"] .circle .fill.fix {
    -webkit-transform: rotate(306deg);
    -ms-transform: rotate(306deg);
    transform: rotate(306deg); }
  .radial-progress[data-progress="85"] .inset .percentage .numbers {
    width: 2150px; }
  .radial-progress[data-progress="86"] .circle .mask.full, .radial-progress[data-progress="86"] .circle .fill {
    -webkit-transform: rotate(154.8deg);
    -ms-transform: rotate(154.8deg);
    transform: rotate(154.8deg); }
  .radial-progress[data-progress="86"] .circle .fill.fix {
    -webkit-transform: rotate(309.6deg);
    -ms-transform: rotate(309.6deg);
    transform: rotate(309.6deg); }
  .radial-progress[data-progress="86"] .inset .percentage .numbers {
    width: 2175px; }
  .radial-progress[data-progress="87"] .circle .mask.full, .radial-progress[data-progress="87"] .circle .fill {
    -webkit-transform: rotate(156.6deg);
    -ms-transform: rotate(156.6deg);
    transform: rotate(156.6deg); }
  .radial-progress[data-progress="87"] .circle .fill.fix {
    -webkit-transform: rotate(313.2deg);
    -ms-transform: rotate(313.2deg);
    transform: rotate(313.2deg); }
  .radial-progress[data-progress="87"] .inset .percentage .numbers {
    width: 2200px; }
  .radial-progress[data-progress="88"] .circle .mask.full, .radial-progress[data-progress="88"] .circle .fill {
    -webkit-transform: rotate(158.4deg);
    -ms-transform: rotate(158.4deg);
    transform: rotate(158.4deg); }
  .radial-progress[data-progress="88"] .circle .fill.fix {
    -webkit-transform: rotate(316.8deg);
    -ms-transform: rotate(316.8deg);
    transform: rotate(316.8deg); }
  .radial-progress[data-progress="88"] .inset .percentage .numbers {
    width: 2225px; }
  .radial-progress[data-progress="89"] .circle .mask.full, .radial-progress[data-progress="89"] .circle .fill {
    -webkit-transform: rotate(160.2deg);
    -ms-transform: rotate(160.2deg);
    transform: rotate(160.2deg); }
  .radial-progress[data-progress="89"] .circle .fill.fix {
    -webkit-transform: rotate(320.4deg);
    -ms-transform: rotate(320.4deg);
    transform: rotate(320.4deg); }
  .radial-progress[data-progress="89"] .inset .percentage .numbers {
    width: 2250px; }
  .radial-progress[data-progress="90"] .circle .mask.full, .radial-progress[data-progress="90"] .circle .fill {
    -webkit-transform: rotate(162deg);
    -ms-transform: rotate(162deg);
    transform: rotate(162deg); }
  .radial-progress[data-progress="90"] .circle .fill.fix {
    -webkit-transform: rotate(324deg);
    -ms-transform: rotate(324deg);
    transform: rotate(324deg); }
  .radial-progress[data-progress="90"] .inset .percentage .numbers {
    width: 2275px; }
  .radial-progress[data-progress="91"] .circle .mask.full, .radial-progress[data-progress="91"] .circle .fill {
    -webkit-transform: rotate(163.8deg);
    -ms-transform: rotate(163.8deg);
    transform: rotate(163.8deg); }
  .radial-progress[data-progress="91"] .circle .fill.fix {
    -webkit-transform: rotate(327.6deg);
    -ms-transform: rotate(327.6deg);
    transform: rotate(327.6deg); }
  .radial-progress[data-progress="91"] .inset .percentage .numbers {
    width: 2300px; }
  .radial-progress[data-progress="92"] .circle .mask.full, .radial-progress[data-progress="92"] .circle .fill {
    -webkit-transform: rotate(165.6deg);
    -ms-transform: rotate(165.6deg);
    transform: rotate(165.6deg); }
  .radial-progress[data-progress="92"] .circle .fill.fix {
    -webkit-transform: rotate(331.2deg);
    -ms-transform: rotate(331.2deg);
    transform: rotate(331.2deg); }
  .radial-progress[data-progress="92"] .inset .percentage .numbers {
    width: 2325px; }
  .radial-progress[data-progress="93"] .circle .mask.full, .radial-progress[data-progress="93"] .circle .fill {
    -webkit-transform: rotate(167.4deg);
    -ms-transform: rotate(167.4deg);
    transform: rotate(167.4deg); }
  .radial-progress[data-progress="93"] .circle .fill.fix {
    -webkit-transform: rotate(334.8deg);
    -ms-transform: rotate(334.8deg);
    transform: rotate(334.8deg); }
  .radial-progress[data-progress="93"] .inset .percentage .numbers {
    width: 2350px; }
  .radial-progress[data-progress="94"] .circle .mask.full, .radial-progress[data-progress="94"] .circle .fill {
    -webkit-transform: rotate(169.2deg);
    -ms-transform: rotate(169.2deg);
    transform: rotate(169.2deg); }
  .radial-progress[data-progress="94"] .circle .fill.fix {
    -webkit-transform: rotate(338.4deg);
    -ms-transform: rotate(338.4deg);
    transform: rotate(338.4deg); }
  .radial-progress[data-progress="94"] .inset .percentage .numbers {
    width: 2375px; }
  .radial-progress[data-progress="95"] .circle .mask.full, .radial-progress[data-progress="95"] .circle .fill {
    -webkit-transform: rotate(171deg);
    -ms-transform: rotate(171deg);
    transform: rotate(171deg); }
  .radial-progress[data-progress="95"] .circle .fill.fix {
    -webkit-transform: rotate(342deg);
    -ms-transform: rotate(342deg);
    transform: rotate(342deg); }
  .radial-progress[data-progress="95"] .inset .percentage .numbers {
    width: 2400px; }
  .radial-progress[data-progress="96"] .circle .mask.full, .radial-progress[data-progress="96"] .circle .fill {
    -webkit-transform: rotate(172.8deg);
    -ms-transform: rotate(172.8deg);
    transform: rotate(172.8deg); }
  .radial-progress[data-progress="96"] .circle .fill.fix {
    -webkit-transform: rotate(345.6deg);
    -ms-transform: rotate(345.6deg);
    transform: rotate(345.6deg); }
  .radial-progress[data-progress="96"] .inset .percentage .numbers {
    width: 2425px; }
  .radial-progress[data-progress="97"] .circle .mask.full, .radial-progress[data-progress="97"] .circle .fill {
    -webkit-transform: rotate(174.6deg);
    -ms-transform: rotate(174.6deg);
    transform: rotate(174.6deg); }
  .radial-progress[data-progress="97"] .circle .fill.fix {
    -webkit-transform: rotate(349.2deg);
    -ms-transform: rotate(349.2deg);
    transform: rotate(349.2deg); }
  .radial-progress[data-progress="97"] .inset .percentage .numbers {
    width: 2450px; }
  .radial-progress[data-progress="98"] .circle .mask.full, .radial-progress[data-progress="98"] .circle .fill {
    -webkit-transform: rotate(176.4deg);
    -ms-transform: rotate(176.4deg);
    transform: rotate(176.4deg); }
  .radial-progress[data-progress="98"] .circle .fill.fix {
    -webkit-transform: rotate(352.8deg);
    -ms-transform: rotate(352.8deg);
    transform: rotate(352.8deg); }
  .radial-progress[data-progress="98"] .inset .percentage .numbers {
    width: 2475px; }
  .radial-progress[data-progress="99"] .circle .mask.full, .radial-progress[data-progress="99"] .circle .fill {
    -webkit-transform: rotate(178.2deg);
    -ms-transform: rotate(178.2deg);
    transform: rotate(178.2deg); }
  .radial-progress[data-progress="99"] .circle .fill.fix {
    -webkit-transform: rotate(356.4deg);
    -ms-transform: rotate(356.4deg);
    transform: rotate(356.4deg); }
  .radial-progress[data-progress="99"] .inset .percentage .numbers {
    width: 2500px; }
  .radial-progress[data-progress="100"] .circle .mask.full, .radial-progress[data-progress="100"] .circle .fill {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg); }
  .radial-progress[data-progress="100"] .circle .fill.fix {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg); }
  .radial-progress[data-progress="100"] .inset .percentage .numbers {
    width: 2525px; }
