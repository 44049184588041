#ball-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  display: block;
  background: transparent; }
  #ball-container #falling-balls {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0; }
  #ball-container .ball {
    display: block;
    position: absolute;
    border-radius: 50%;
    box-sizing: border-box;
    font-size: 8pt;
    text-align: center; }
  #ball-container .image {
    margin: auto;
    background-repeat: no-repeat;
    background-size: contain; }
  #ball-container .text-wrap {
    font-size: 14px; }
  #ball-container .inline .image {
    display: inline-block; }
  #ball-container .inline .text-wrap {
    display: inline-block;
    text-align: left;
    vertical-align: top;
    font-size: 16px;
    margin-left: 15px; }
  #ball-container #fallingBalls_subscribe_icon {
    color: white; }
  #ball-container #fallingBalls_subscribe {
    color: white; }
  #ball-container .falling-balls-header {
    display: flex; }
  #ball-container .falling-balls-content-wrapper {
    font-size: 2.5rem;
    font-weight: 200;
    position: relative;
    line-height: normal;
    color: white;
    text-shadow: 0px 0px 5px black; }
    #ball-container .falling-balls-content-wrapper h2 {
      font-size: 3rem;
      font-weight: 300; }
  @media (max-width: 1600px) {
    #ball-container .text-wrap {
      font-size: 12px; } }
  @media (max-width: 1370px) {
    #ball-container .text-wrap {
      font-size: 10px; } }
  @media (min-width: 992px) and (min-height: 600px) {
    #ball-container .falling-balls-header {
      height: 20%; } }
  @media (min-width: 300px) and (min-height: 200px) {
    #ball-container .falling-balls-content-wrapper {
      font-size: 1.5rem; } }
  @media (min-width: 400px) and (min-height: 200px) {
    #ball-container .falling-balls-content-wrapper {
      font-size: 2rem; } }
  @media (min-width: 300px) and (min-height: 800px) {
    #ball-container .falling-balls-content-wrapper {
      font-size: 2.5rem; } }
