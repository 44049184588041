.floating-ball {
  position: absolute;
  border-radius: 100%; }

.float-normal {
  animation: float 10s linear infinite;
  transform-origin: 100% center; }

.float-slow {
  animation: float 20s linear infinite;
  transform-origin: 100% center; }

.float-backwards {
  animation: floatbw 10s linear infinite;
  transform-origin: 100% center; }

.float-backwards-slow {
  animation: floatbw 20s linear infinite;
  transform-origin: 100% center; }

.float-small-circle {
  animation: float 20s linear infinite;
  transform-origin: 65% center; }

@keyframes float {
  100% {
    transform: rotate(360deg); } }

@keyframes floatbw {
  100% {
    transform: rotate(-360deg); } }
