.circle-button {
  color: white;
  border: 2px solid white;
  border-radius: 100%;
  width: 52px;
  height: 52px;
  display: inline-block;
  line-height: 36px;
  font-size: 30px;
  cursor: pointer;
  position: relative;
  margin: 0 5px;
  user-select: none;
  background: transparent;
  padding: 0;
  outline: none !important;
  transition: background-color 0.25s, color 0.25s; }
  .circle-button.clicked {
    background-color: #7C7B7C;
    color: black; }
    .circle-button.clicked:before {
      animation: opacitychange 0.25s linear;
      transform: scale(1.5, 1.5); }
  .circle-button[disabled] {
    opacity: 0.4; }
  .circle-button:before {
    content: '';
    opacity: 0;
    width: 100%;
    display: inline-block;
    height: 100%;
    border: 2px solid white;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 100%;
    transform-origin: center;
    transition: transform 0.25s; }
  .circle-button span.circle-button-wrapper {
    top: -2px;
    position: relative; }
  .circle-button.white {
    color: black;
    border-color: black; }
    .circle-button.white:before {
      border-color: black; }
    .circle-button.white.clicked {
      color: white;
      border-color: white;
      background-color: black; }

@media (max-width: 1600px) {
  .circle-button {
    height: 40px;
    width: 40px; } }

@keyframes opacitychange {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }
