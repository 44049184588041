#stock-ekg-diagram, #fund-ekg-diagram {
  overflow: hidden; }
  #stock-ekg-diagram .facs-cta, #fund-ekg-diagram .facs-cta {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding-top: 8px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 16px; }
    #stock-ekg-diagram .facs-cta a, #fund-ekg-diagram .facs-cta a {
      color: white;
      font-weight: bold; }
  #stock-ekg-diagram #stock-canvas, #stock-ekg-diagram #fund-canvas, #fund-ekg-diagram #stock-canvas, #fund-ekg-diagram #fund-canvas {
    margin-top: 20px; }
  #stock-ekg-diagram .tab-header, #fund-ekg-diagram .tab-header {
    display: flex; }
    #stock-ekg-diagram .tab-header .tab-header-item, #fund-ekg-diagram .tab-header .tab-header-item {
      background: #101010;
      color: #7f7f7f;
      border-radius: 4px 4px 0px 0px;
      border-bottom: none;
      margin-bottom: -1px;
      margin-right: 2px;
      padding: 5px 10px;
      font-size: 14pt;
      cursor: pointer;
      width: 50%;
      box-sizing: border-box; }
      #stock-ekg-diagram .tab-header .tab-header-item#fund-compare-funds-tab, #fund-ekg-diagram .tab-header .tab-header-item#fund-compare-funds-tab {
        width: 100%;
        margin-right: 0; }
      #stock-ekg-diagram .tab-header .tab-header-item#fund-compare-factors-tab, #fund-ekg-diagram .tab-header .tab-header-item#fund-compare-factors-tab {
        display: none; }
      #stock-ekg-diagram .tab-header .tab-header-item:last-child, #fund-ekg-diagram .tab-header .tab-header-item:last-child {
        margin-right: 0; }
      #stock-ekg-diagram .tab-header .tab-header-item.white, #fund-ekg-diagram .tab-header .tab-header-item.white {
        background: black; }
      #stock-ekg-diagram .tab-header .tab-header-item.active, #fund-ekg-diagram .tab-header .tab-header-item.active {
        background: #202020;
        color: white; }
        #stock-ekg-diagram .tab-header .tab-header-item.active.white, #fund-ekg-diagram .tab-header .tab-header-item.active.white {
          background: #002036;
          color: white; }
  #stock-ekg-diagram .tab-content, #fund-ekg-diagram .tab-content {
    display: block;
    background: rgba(32, 32, 32, 0.9);
    color: white;
    padding: 10px;
    overflow: hidden; }
    #stock-ekg-diagram .tab-content.white, #fund-ekg-diagram .tab-content.white {
      color: white;
      background: #002036; }
  #stock-ekg-diagram .gray-wrapper, #fund-ekg-diagram .gray-wrapper {
    display: flex;
    background: rgba(32, 32, 32, 0.9);
    color: white;
    padding: 10px;
    border-radius: 0px 0px 4px 4px; }
    #stock-ekg-diagram .gray-wrapper.white, #fund-ekg-diagram .gray-wrapper.white {
      background: #002036; }
    #stock-ekg-diagram .gray-wrapper.no-border-radius, #fund-ekg-diagram .gray-wrapper.no-border-radius {
      border-radius: 0px; }
    #stock-ekg-diagram .gray-wrapper.no-padding, #fund-ekg-diagram .gray-wrapper.no-padding {
      padding: 0px; }
    #stock-ekg-diagram .gray-wrapper #stock-add-stocks-panel, #stock-ekg-diagram .gray-wrapper #fund-add-stocks-panel, #fund-ekg-diagram .gray-wrapper #stock-add-stocks-panel, #fund-ekg-diagram .gray-wrapper #fund-add-stocks-panel {
      padding: 0px 10px; }
    #stock-ekg-diagram .gray-wrapper.bottom-facs-cta, #fund-ekg-diagram .gray-wrapper.bottom-facs-cta {
      border-top: 1px solid rgba(255, 255, 255, 0.1);
      padding: 10px;
      padding-top: 0;
      line-height: 40px;
      font-size: 20px;
      text-align: center; }
      #stock-ekg-diagram .gray-wrapper.bottom-facs-cta a, #fund-ekg-diagram .gray-wrapper.bottom-facs-cta a {
        width: 49%;
        text-decoration: none; }
      #stock-ekg-diagram .gray-wrapper.bottom-facs-cta .cta-button, #fund-ekg-diagram .gray-wrapper.bottom-facs-cta .cta-button {
        width: 100%;
        font-size: 18px;
        line-height: 24px;
        color: white;
        background-color: #1A3FD6;
        padding: 8px; }
        #stock-ekg-diagram .gray-wrapper.bottom-facs-cta .cta-button:hover, #fund-ekg-diagram .gray-wrapper.bottom-facs-cta .cta-button:hover {
          background-color: #4A7BC9; }
    #stock-ekg-diagram .gray-wrapper #stock-add-stocks-panel-content input, #stock-ekg-diagram .gray-wrapper #fund-add-funds-panel-content input, #fund-ekg-diagram .gray-wrapper #stock-add-stocks-panel-content input, #fund-ekg-diagram .gray-wrapper #fund-add-funds-panel-content input {
      margin: 0px 10px; }
    #stock-ekg-diagram .gray-wrapper #stock-add-stocks-panel-content .autocomplete-items, #stock-ekg-diagram .gray-wrapper #fund-add-funds-panel-content .autocomplete-items, #fund-ekg-diagram .gray-wrapper #stock-add-stocks-panel-content .autocomplete-items, #fund-ekg-diagram .gray-wrapper #fund-add-funds-panel-content .autocomplete-items {
      padding-left: 10px;
      padding-right: 10px; }
  #stock-ekg-diagram graph-list-container, #fund-ekg-diagram graph-list-container {
    position: absolute;
    /*min-width: 400px;*/
    top: 15px;
    /*right: 75px;*/ }
  #stock-ekg-diagram .close, #fund-ekg-diagram .close {
    margin-left: 12px;
    position: absolute;
    float: left;
    font-size: 28px;
    font-weight: bold;
    color: gray; }
  #stock-ekg-diagram #stock-ekg-factor-select, #stock-ekg-diagram #stock-ekg-stock-select, #stock-ekg-diagram #fund-ekg-factor-select, #stock-ekg-diagram #fund-ekg-stock-select, #fund-ekg-diagram #stock-ekg-factor-select, #fund-ekg-diagram #stock-ekg-stock-select, #fund-ekg-diagram #fund-ekg-factor-select, #fund-ekg-diagram #fund-ekg-stock-select {
    background: black;
    color: white;
    border: 1px solid white; }
    #stock-ekg-diagram #stock-ekg-factor-select.white, #stock-ekg-diagram #stock-ekg-stock-select.white, #stock-ekg-diagram #fund-ekg-factor-select.white, #stock-ekg-diagram #fund-ekg-stock-select.white, #fund-ekg-diagram #stock-ekg-factor-select.white, #fund-ekg-diagram #stock-ekg-stock-select.white, #fund-ekg-diagram #fund-ekg-factor-select.white, #fund-ekg-diagram #fund-ekg-stock-select.white {
      background: #002036;
      color: rgba(255, 255, 255, 0.9);
      border: 1px solid black; }
    #stock-ekg-diagram #stock-ekg-factor-select:focus, #stock-ekg-diagram #stock-ekg-stock-select:focus, #stock-ekg-diagram #fund-ekg-factor-select:focus, #stock-ekg-diagram #fund-ekg-stock-select:focus, #fund-ekg-diagram #stock-ekg-factor-select:focus, #fund-ekg-diagram #stock-ekg-stock-select:focus, #fund-ekg-diagram #fund-ekg-factor-select:focus, #fund-ekg-diagram #fund-ekg-stock-select:focus {
      outline: none; }
  #stock-ekg-diagram #stock-ekg-switches, #stock-ekg-diagram #stock-ekg-factor-switches, #stock-ekg-diagram #fund-ekg-switches, #stock-ekg-diagram #fund-ekg-factor-switches, #fund-ekg-diagram #stock-ekg-switches, #fund-ekg-diagram #stock-ekg-factor-switches, #fund-ekg-diagram #fund-ekg-switches, #fund-ekg-diagram #fund-ekg-factor-switches {
    margin-top: 8px; }
    #stock-ekg-diagram #stock-ekg-switches .asset-switch-label, #stock-ekg-diagram #stock-ekg-factor-switches .asset-switch-label, #stock-ekg-diagram #fund-ekg-switches .asset-switch-label, #stock-ekg-diagram #fund-ekg-factor-switches .asset-switch-label, #fund-ekg-diagram #stock-ekg-switches .asset-switch-label, #fund-ekg-diagram #stock-ekg-factor-switches .asset-switch-label, #fund-ekg-diagram #fund-ekg-switches .asset-switch-label, #fund-ekg-diagram #fund-ekg-factor-switches .asset-switch-label {
      color: white;
      display: inline-block;
      vertical-align: top;
      font-size: 12pt;
      margin-left: 12px;
      padding-top: 6px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 66%; }
  #stock-ekg-diagram .btn, #fund-ekg-diagram .btn {
    line-height: 15px;
    border: 2px solid black;
    border-radius: 40px;
    transition: all 0.3s ease 0s; }
  #stock-ekg-diagram .btn:focus, #fund-ekg-diagram .btn:focus {
    outline: none;
    box-shadow: none; }
  #stock-ekg-diagram #stock-ekg-overlay, #stock-ekg-diagram #fund-ekg-overlay, #fund-ekg-diagram #stock-ekg-overlay, #fund-ekg-diagram #fund-ekg-overlay {
    position: absolute;
    display: block;
    top: 200px;
    left: 1000px;
    background: rgba(32, 32, 32, 0.8);
    border-radius: 4px;
    padding: 10px;
    color: white;
    pointer-events: none;
    width: fit-content; }
    #stock-ekg-diagram #stock-ekg-overlay.white, #stock-ekg-diagram #fund-ekg-overlay.white, #fund-ekg-diagram #stock-ekg-overlay.white, #fund-ekg-diagram #fund-ekg-overlay.white {
      background: #002036;
      color: white; }
  #stock-ekg-diagram #stock-ekg-company-input, #stock-ekg-diagram #fund-ekg-company-input, #fund-ekg-diagram #stock-ekg-company-input, #fund-ekg-diagram #fund-ekg-company-input {
    width: calc(100% - 20px); }
  #stock-ekg-diagram .progress-bar-content, #fund-ekg-diagram .progress-bar-content {
    display: flex;
    width: 100%;
    margin-left: auto; }
  #stock-ekg-diagram .progress-bar-wrapper, #fund-ekg-diagram .progress-bar-wrapper {
    position: absolute;
    display: none;
    margin: 0;
    bottom: 5px;
    left: 38.2%;
    transform: translate(-50%); }
    #stock-ekg-diagram .progress-bar-wrapper .lbl-line, #fund-ekg-diagram .progress-bar-wrapper .lbl-line {
      opacity: 0;
      position: relative;
      top: 0px;
      right: 45px; }
  #stock-ekg-diagram .exposure-bar-label, #fund-ekg-diagram .exposure-bar-label {
    text-align: center;
    margin-top: 2%;
    width: 70%;
    margin-left: auto; }
  #stock-ekg-diagram .progress-bar-text, #fund-ekg-diagram .progress-bar-text {
    margin: auto;
    color: white; }
  #stock-ekg-diagram .exposure-bar, #fund-ekg-diagram .exposure-bar {
    width: 12.5%;
    display: inline-block;
    font-size: 0%;
    height: 100%; }
  #stock-ekg-diagram .base-bar, #fund-ekg-diagram .base-bar {
    height: 15px;
    width: 0%; }
    #stock-ekg-diagram .base-bar.animate-bar, #fund-ekg-diagram .base-bar.animate-bar {
      width: 0%; }
    #stock-ekg-diagram .base-bar.first, #fund-ekg-diagram .base-bar.first {
      border-bottom-left-radius: 1.5rem;
      border-top-left-radius: 1.5rem; }
    #stock-ekg-diagram .base-bar.last, #fund-ekg-diagram .base-bar.last {
      border-bottom-right-radius: 1.5rem;
      border-top-right-radius: 1.5rem; }
  #stock-ekg-diagram .red1, #fund-ekg-diagram .red1 {
    background-color: #e3091f; }
  #stock-ekg-diagram .red2, #fund-ekg-diagram .red2 {
    background-color: #f83231; }
  #stock-ekg-diagram .red3, #fund-ekg-diagram .red3 {
    background-color: #fb6521; }
  #stock-ekg-diagram .orange, #fund-ekg-diagram .orange {
    background-color: #fea310; }
  #stock-ekg-diagram .yellow, #fund-ekg-diagram .yellow {
    background-color: #f0f220; }
  #stock-ekg-diagram .green1, #fund-ekg-diagram .green1 {
    background-color: #befb96; }
  #stock-ekg-diagram .green2, #fund-ekg-diagram .green2 {
    background-color: #20fa61; }
  #stock-ekg-diagram .green3, #fund-ekg-diagram .green3 {
    background-color: #0fd34a; }
  #stock-ekg-diagram .visualization-poc-logo, #fund-ekg-diagram .visualization-poc-logo {
    margin-left: 60px; }
  #stock-ekg-diagram .scene-container, #fund-ekg-diagram .scene-container {
    width: 1470px;
    position: absolute;
    top: 8px;
    display: inline-grid;
    grid-template-columns: minmax(5px, 50px) minmax(160px, 200px) auto minmax(160px, 200px) minmax(5px, 50px); }
    #stock-ekg-diagram .scene-container i.subscribe, #fund-ekg-diagram .scene-container i.subscribe {
      margin-top: 7px;
      margin-left: 7px; }
  #stock-ekg-diagram .logo, #fund-ekg-diagram .logo {
    filter: grayscale(0.5) brightness(400%);
    grid-column-start: 2;
    grid-column-end: 3; }
  #stock-ekg-diagram .title, #fund-ekg-diagram .title {
    color: #fff;
    grid-column-start: 3;
    grid-column-end: 4;
    line-height: 22px;
    font-size: 26px;
    display: block;
    font-weight: 500;
    padding-top: 6px; }
  #stock-ekg-diagram .description-container, #fund-ekg-diagram .description-container {
    grid-column-start: 5;
    top: 50%;
    right: 0px;
    margin: 40px 0 40px 0;
    position: fixed;
    transform: rotate(-90deg) translateX(50%) translateX(80px);
    transform-origin: bottom right; }
  #stock-ekg-diagram .description, #fund-ekg-diagram .description {
    color: lightgray;
    font-size: 12px;
    white-space: nowrap; }
  @media (max-height: 600px) {
    #stock-ekg-diagram .header-visualization-poc-logo, #fund-ekg-diagram .header-visualization-poc-logo {
      grid-template-columns: minmax(5px, 50px) 120px auto 120px minmax(5px, 50px); }
    #stock-ekg-diagram .title, #fund-ekg-diagram .title {
      line-height: 19px;
      font-size: 18px; } }
  @media (max-height: 425px) {
    #stock-ekg-diagram .header-visualization-poc-logo, #fund-ekg-diagram .header-visualization-poc-logo {
      grid-template-columns: minmax(5px, 50px) 70px auto 700px minmax(5px, 50px); }
    #stock-ekg-diagram .title, #fund-ekg-diagram .title {
      line-height: 16px;
      font-size: 14px; }
    #stock-ekg-diagram .description, #fund-ekg-diagram .description {
      color: lightgray;
      font-size: 8px;
      white-space: nowrap; } }
  @media (max-width: 360px) {
    #stock-ekg-diagram .header-visualization-poc-logo, #fund-ekg-diagram .header-visualization-poc-logo {
      width: 70px; }
    #stock-ekg-diagram .title, #fund-ekg-diagram .title {
      line-height: 16px;
      font-size: 14px; } }

#stock-ekg-modal-cta, #fund-ekg-modal-cta {
  width: 500px; }
  #stock-ekg-modal-cta .bottom-facs-cta, #fund-ekg-modal-cta .bottom-facs-cta {
    padding: 10px;
    padding-top: 0;
    text-align: center; }
    #stock-ekg-modal-cta .bottom-facs-cta .title, #fund-ekg-modal-cta .bottom-facs-cta .title {
      line-height: 24px;
      font-size: 24px; }
    #stock-ekg-modal-cta .bottom-facs-cta .header, #fund-ekg-modal-cta .bottom-facs-cta .header {
      margin-top: 10px;
      font-size: 18px; }
    #stock-ekg-modal-cta .bottom-facs-cta a, #fund-ekg-modal-cta .bottom-facs-cta a {
      width: 49%;
      text-decoration: none; }
    #stock-ekg-modal-cta .bottom-facs-cta .cta-button, #fund-ekg-modal-cta .bottom-facs-cta .cta-button {
      width: 100%;
      font-size: 18px;
      line-height: 24px;
      color: white;
      background-color: #1A3FD6;
      padding: 8px; }
      #stock-ekg-modal-cta .bottom-facs-cta .cta-button:hover, #fund-ekg-modal-cta .bottom-facs-cta .cta-button:hover {
        background-color: #4A7BC9; }
