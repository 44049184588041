#landing_wrapper.page-wrapper {
  overflow: hidden;
  height: 200%; }

#landing-visualization-poc-logo {
  opacity: 0; }

#landing_top {
  height: 50%; }

#landing_text1 {
  width: 60%;
  line-height: 5rem; }

#landing_subtitle {
  margin-bottom: 48px; }

#landing_text2 {
  width: 44%;
  font-size: 1rem;
  margin-bottom: 100px; }

#landing_subscribe {
  opacity: 0;
  transition: opacity 2s; }

#landing_subscribe_icon {
  opacity: 0;
  transition: opacity 2s; }

.lift-up-text {
  color: black;
  font-size: 1rem; }

.curved-text {
  color: black;
  font-size: 4rem; }

#explore {
  border: 3px solid black;
  border-radius: 50px;
  padding: 12px 30px;
  font-size: 1.5rem;
  margin: 0 auto; }

#landing_bottom {
  position: relative;
  height: 50%;
  color: white; }

#landing_ball_padding {
  padding: 0 5.7%;
  height: 100%;
  position: relative; }

#landing-arrow-wrapper {
  transition: opacity 2s;
  opacity: 0;
  position: absolute;
  bottom: 40px;
  right: 40px; }

#landing-ball-menu-container {
  height: 100%; }

#landing_bottom_header_wrapper {
  top: 130px;
  position: absolute;
  width: 100%; }
  #landing_bottom_header_wrapper #bubble-logo {
    opacity: 0;
    transition: opacity 1s; }
  #landing_bottom_header_wrapper #bubble-subscribe {
    opacity: 0;
    transition: opacity 1s; }
  #landing_bottom_header_wrapper #bubble-subscribe-icon {
    opacity: 0;
    transition: opacity 3s; }

#selected_menu_text_wrapper {
  color: white;
  position: relative;
  top: -10%;
  text-align: center;
  width: 100%; }
  #selected_menu_text_wrapper .menu-item {
    display: block;
    opacity: 0;
    transition: opacity 1s;
    width: 100%; }
    #selected_menu_text_wrapper .menu-item.fade-in {
      opacity: 1; }
      #selected_menu_text_wrapper .menu-item.fade-in.fade-out {
        opacity: 0; }
  #selected_menu_text_wrapper .selected_menu_title {
    font-size: 1.6rem; }
  #selected_menu_text_wrapper .selected_menu_description {
    left: 25%;
    color: #ffffff;
    font-size: 1.4rem; }

#landing_bottom_visualization-poc_facs_text {
  top: 110px;
  position: absolute;
  width: 100%;
  text-align: center;
  visibility: hidden; }

#landing_facs_title {
  font-size: 1.6rem;
  margin-bottom: 40px;
  color: white; }
  #landing_facs_title.lift-up-text {
    top: 50px; }
    #landing_facs_title.lift-up-text.animation-active {
      transition: opacity 1s, top 1s; }
    #landing_facs_title.lift-up-text.animation-complete {
      top: 0; }

#landing_facs_description {
  width: 40%;
  margin: 0 auto;
  color: #ffffff;
  font-size: 1.5rem; }
  #landing_facs_description.lift-up-text {
    top: 50px; }
    #landing_facs_description.lift-up-text.animation-active {
      transition: opacity 1s, top 1s; }
    #landing_facs_description.lift-up-text.animation-complete {
      top: 0; }

@media (max-width: 1895px) {
  #landing_text1 {
    width: 65%; }
  #landing_text2 {
    width: 60%; } }

@media (max-width: 1770px) {
  #landing_facs_description {
    width: 50%; }
  #landing_text1 {
    width: 70%; } }

@media (max-width: 1690px) {
  .curved-text {
    font-size: 3.5rem; }
  #selected_menu_text_wrapper {
    top: -15%; } }

@media (max-width: 1490px) {
  #landing_text1 {
    width: 75%; }
  #landing_text2 {
    width: 65%; }
  #landing_facs_description {
    width: 60%; } }

@media (max-width: 1405px) {
  #landing_text1 {
    width: 80%; } }

@media (max-width: 1370px) {
  #selected_menu_text_wrapper {
    top: -15%; } }

@media (max-width: 1340px) {
  .curved-text {
    font-size: 3rem; }
  #landing_text2 {
    width: 75%; } }

@media (max-width: 1110px) {
  #landing_text2 {
    width: 80%; } }

@media (max-width: 1050px) {
  #landing_text1 {
    width: 90%;
    margin-bottom: 15px;
    line-height: 2.5rem; }
  #landing_text2 {
    width: 90%; }
  .lift-up-text {
    font-size: 0.9rem; } }

@media (max-width: 950px) {
  .curved-text {
    font-size: 2.5rem; }
  #landing_bottom_header_wrapper {
    top: 75px; }
  #landing_bottom_visualization-poc_facs_text {
    top: 0;
    text-align: left; }
  #landing_facs_description {
    left: 30%;
    display: inline-block;
    width: 70%; }
    #landing_facs_description.lift-up-text.animation-complete {
      top: 80px; }
  #landing_facs_title.lift-up-text.animation-complete {
    top: 140px; } }

@media (max-width: 815px) {
  .curved-text {
    font-size: 2rem; } }

@media (max-width: 680px) {
  .curved-text {
    font-size: 2rem; }
  #landing_ball_padding {
    padding: 0 10%; } }

@media (max-width: 610px) {
  #landing_text1 {
    width: 100%;
    line-height: 2rem; } }

@media (max-width: 560px) {
  .curved-text {
    font-size: 1.5rem; }
  #landing_facs_description {
    left: 0;
    width: 100%; }
    #landing_facs_description.lift-up-text.animation-complete {
      top: 115px; }
  #landing-arrow-wrapper {
    bottom: 45px;
    right: unset;
    text-align: right;
    width: 100%; }
  #landing_ball_padding {
    padding: 0 15%; }
  #selected_menu_text_wrapper {
    top: -175px; }
    #selected_menu_text_wrapper .selected_menu_description {
      top: 50px;
      left: 0;
      width: 100%; } }

@media (max-width: 420px) {
  #explore {
    padding: 6px 30px;
    font-size: 1rem; }
  #landing_ball_padding {
    padding: 0 20%; } }

@media (max-height: 850px) {
  #landing_text2 {
    margin-bottom: 75px; }
  #landing_text_wrap {
    top: 40%; }
  #landing_bottom_header_wrapper {
    top: 75px; } }

@media (max-height: 750px) {
  #landing_text2 {
    margin-bottom: 75px; } }

@media (max-height: 600px) {
  #landing_text2 {
    margin-bottom: 25px; }
  #landing_bottom_header_wrapper {
    top: 20px; }
  #landing_facs_title.lift-up-text.animation-complete {
    top: 75px; }
  #landing_facs_description.lift-up-text.animation-complete {
    top: 50px; } }

@media (max-height: 460px) {
  #landing_bottom_header_wrapper {
    top: 20px; }
  #landing_facs_title.lift-up-text.animation-complete {
    top: 90px;
    font-size: 1rem; }
  #landing_facs_description.lift-up-text.animation-complete {
    top: 25px;
    font-size: 0.75rem; } }

@media (max-height: 425px) {
  #landing_text_wrap {
    top: 15px;
    transform: none;
    left: 0; }
  #explore {
    padding: 6px 30px;
    font-size: 1rem; } }

@media (max-height: 395px) {
  #landing_bottom_header_wrapper {
    top: 40px; } }

@media (max-height: 370px) {
  #landing_text2 {
    margin-bottom: 10px; } }
