.floating-balls-header {
  display: flex; }
  .floating-balls-header #floating-balls-logo-id {
    opacity: 0; }
  .floating-balls-header #floating_share {
    opacity: 0; }
  .floating-balls-header #join-wrapper {
    z-index: 1;
    text-align: center; }
    .floating-balls-header #join-wrapper span {
      font-size: 1.4rem;
      font-weight: bold; }
    .floating-balls-header #join-wrapper img {
      margin: 5px; }
    .floating-balls-header #join-wrapper .twitter {
      width: 35px; }
    .floating-balls-header #join-wrapper .linkedin {
      width: 44px; }

.floating-balls-content-wrapper {
  font-size: 2.75rem;
  position: relative;
  line-height: normal;
  top: 10%; }
  .floating-balls-content-wrapper .floating-balls-content {
    margin-bottom: 1.5rem; }

#subscribe-button {
  background: transparent;
  border: 3px solid black;
  margin-top: 35px;
  border-radius: 50px;
  padding: 16px 63px;
  font-size: 1.5rem;
  font-weight: bold; }

@media (max-width: 1600px) {
  .floating-balls-content-wrapper {
    font-size: 2rem; }
    .floating-balls-content-wrapper .floating-balls-content {
      margin-bottom: 1.25rem; } }

@media (max-width: 1366px) {
  .floating-balls-content-wrapper {
    font-size: 1.75rem; }
    .floating-balls-content-wrapper .floating-balls-content {
      margin-bottom: 1rem; }
  #subscribe-button {
    padding: 13px 40px; } }

@media (max-width: 1024px) {
  .floating-balls-content-wrapper {
    font-size: 1.3rem; }
  #subscribe-button {
    padding: 10px 35px;
    margin-top: 25px;
    font-size: 1.25rem; } }

@media (max-width: 668px) {
  .floating-balls-content-wrapper {
    font-size: 0.8rem; }
  #subscribe-button {
    padding: 8px 30px;
    font-size: 1.3rem;
    margin-top: 20px; } }
