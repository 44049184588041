@media (min-width: 992px) {
  .rightContent {
    display: flex; }
  .sub-page-header {
    height: 20%; } }

.sub-page-header {
  display: flex;
  margin-bottom: 30px; }

.sub-page-content-wrapper {
  left: 50%;
  position: relative;
  transform: translate(-50%); }

#sub-page-topic {
  font-size: 3rem;
  font-weight: 800; }

#sub-page-title {
  font-size: 2rem;
  font-weight: bold;
  line-height: normal; }

#sub-page-subtitle {
  margin-top: 16px;
  font-size: 1.8rem;
  line-height: normal;
  font-weight: bold; }

#sub-page-content1 {
  font-size: 1.25rem;
  margin-top: 20px; }

#sub-page-content2 {
  font-size: 1.25rem; }

@media (min-width: 667px) and (max-width: 991px) {
  .sub-page-header {
    height: 6%; }
  #sub-page-topic {
    font-size: 1.5rem;
    font-weight: 800;
    max-width: 100%; }
  #sub-page-title {
    font-size: 1.25rem; }
  #sub-page-subtitle {
    font-size: 1.25rem; }
  #sub-page-content-wrapper {
    max-width: 100%; } }

@media (min-width: 992px) and (max-width: 1200px) {
  #sub-page-topic {
    font-size: 1.25rem;
    font-weight: 800; }
  #sub-page-title {
    font-size: 1rem; }
  #sub-page-subtitle {
    font-size: 0.9rem; }
  #sub-page-content1 {
    font-size: 0.9rem; }
  #sub-page-content2 {
    font-size: 0.9rem; } }

@media (max-height: 893px) {
  .sub-page-header {
    height: 10%; }
  #sub-page-topic {
    font-size: 1.8rem;
    font-weight: 800; }
  #sub-page-title {
    font-size: 1.5rem; }
  #sub-page-subtitle {
    font-size: 1.2rem; }
  #sub-page-content1 {
    font-size: 1.2rem; }
  #sub-page-content2 {
    font-size: 1.2rem; } }

@media (max-width: 1250px) and (max-height: 452px) {
  .rightContent {
    margin-top: 20px;
    max-width: 100%; }
  #sub-page-topic {
    font-size: 1.5rem;
    font-weight: 800; }
  #sub-page-title {
    font-size: 1.25rem; }
  #sub-page-subtitle {
    font-size: 1rem; }
  #sub-page-content1 {
    font-size: 0.9rem; }
  #sub-page-content2 {
    font-size: 0.9rem; } }

@media (max-height: 680px) {
  .sub-page-header {
    height: 6%; }
  #sub-page-topic {
    font-size: 1.5rem;
    font-weight: 800; }
  #sub-page-title {
    font-size: 1.25rem; }
  #sub-page-subtitle {
    font-size: 1rem; }
  #sub-page-content1 {
    font-size: 0.9rem; }
  #sub-page-content2 {
    font-size: 0.9rem; } }

@media (max-width: 667px) {
  .rightContent {
    margin-top: 15px;
    max-width: 100%; }
  .sub-page-header {
    height: 6%;
    margin-bottom: 10px; }
  #sub-page-topic {
    font-size: 1.5rem;
    font-weight: 800; }
  #sub-page-title {
    font-size: 1.2rem; }
  #sub-page-subtitle {
    font-size: 1rem; }
  #sub-page-content1 {
    font-size: 0.8rem;
    margin-top: 10px; }
  #sub-page-content2 {
    font-size: 0.8rem; } }

#sub-page-subscribe.animation-active {
  transition: opacity 3s, top 0.5s; }
