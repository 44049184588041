#fund-factor-box, #stock-factor-box {
  width: 100%; }
  #fund-factor-box #fund-title-container, #fund-factor-box #stock-title-container, #stock-factor-box #fund-title-container, #stock-factor-box #stock-title-container {
    margin-bottom: 20px;
    padding-top: 15px;
    padding-bottom: 2px;
    display: flex;
    justify-content: center;
    font-size: 1.6rem; }
    #fund-factor-box #fund-title-container .title-separator, #fund-factor-box #stock-title-container .title-separator, #stock-factor-box #fund-title-container .title-separator, #stock-factor-box #stock-title-container .title-separator {
      color: transparent; }
  #fund-factor-box #fund-bottom-container, #fund-factor-box #stock-bottom-container, #stock-factor-box #fund-bottom-container, #stock-factor-box #stock-bottom-container {
    display: flex;
    width: 100%;
    padding: 10px 0px;
    font-size: 17px;
    opacity: 0.6;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap; }
  #fund-factor-box #fund-factor-box-overlay, #fund-factor-box #stock-factor-box-overlay, #stock-factor-box #fund-factor-box-overlay, #stock-factor-box #stock-factor-box-overlay {
    position: absolute;
    display: block;
    top: 200px;
    left: 1000px;
    background: rgba(32, 32, 32, 0.8);
    border-radius: 4px;
    padding: 10px;
    color: white;
    pointer-events: none;
    width: fit-content; }
    #fund-factor-box #fund-factor-box-overlay.white, #fund-factor-box #stock-factor-box-overlay.white, #stock-factor-box #fund-factor-box-overlay.white, #stock-factor-box #stock-factor-box-overlay.white {
      background: #002036;
      color: white; }
  #fund-factor-box #fund-canvas-container, #fund-factor-box #stock-canvas-container, #stock-factor-box #fund-canvas-container, #stock-factor-box #stock-canvas-container {
    display: flex; }
    #fund-factor-box #fund-canvas-container .left, #fund-factor-box #fund-canvas-container .right, #fund-factor-box #fund-canvas-container .sep, #fund-factor-box #stock-canvas-container .left, #fund-factor-box #stock-canvas-container .right, #fund-factor-box #stock-canvas-container .sep, #stock-factor-box #fund-canvas-container .left, #stock-factor-box #fund-canvas-container .right, #stock-factor-box #fund-canvas-container .sep, #stock-factor-box #stock-canvas-container .left, #stock-factor-box #stock-canvas-container .right, #stock-factor-box #stock-canvas-container .sep {
      flex: 1; }
    #fund-factor-box #fund-canvas-container #fund-label-container, #fund-factor-box #fund-canvas-container #stock-label-container, #fund-factor-box #stock-canvas-container #fund-label-container, #fund-factor-box #stock-canvas-container #stock-label-container, #stock-factor-box #fund-canvas-container #fund-label-container, #stock-factor-box #fund-canvas-container #stock-label-container, #stock-factor-box #stock-canvas-container #fund-label-container, #stock-factor-box #stock-canvas-container #stock-label-container {
      display: flex;
      margin-top: 75px;
      margin-bottom: -90px;
      flex-direction: column;
      justify-content: space-around; }
      #fund-factor-box #fund-canvas-container #fund-label-container .circle-label, #fund-factor-box #fund-canvas-container #stock-label-container .circle-label, #fund-factor-box #stock-canvas-container #fund-label-container .circle-label, #fund-factor-box #stock-canvas-container #stock-label-container .circle-label, #stock-factor-box #fund-canvas-container #fund-label-container .circle-label, #stock-factor-box #fund-canvas-container #stock-label-container .circle-label, #stock-factor-box #stock-canvas-container #fund-label-container .circle-label, #stock-factor-box #stock-canvas-container #stock-label-container .circle-label {
        display: flex;
        flex-direction: column;
        align-items: center; }
      #fund-factor-box #fund-canvas-container #fund-label-container .circle, #fund-factor-box #fund-canvas-container #stock-label-container .circle, #fund-factor-box #stock-canvas-container #fund-label-container .circle, #fund-factor-box #stock-canvas-container #stock-label-container .circle, #stock-factor-box #fund-canvas-container #fund-label-container .circle, #stock-factor-box #fund-canvas-container #stock-label-container .circle, #stock-factor-box #stock-canvas-container #fund-label-container .circle, #stock-factor-box #stock-canvas-container #stock-label-container .circle {
        display: block;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        position: relative;
        background-size: 90%;
        background-position: center; }
  #fund-factor-box .stock, #stock-factor-box .stock {
    margin-top: 10px;
    display: flex;
    align-items: center; }
  #fund-factor-box label, #stock-factor-box label {
    margin-bottom: unset; }
  #fund-factor-box .factor-asset-switch-label, #stock-factor-box .factor-asset-switch-label {
    margin-left: 15px;
    margin-right: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 66%; }
  #fund-factor-box .switch .pseudo, #stock-factor-box .switch .pseudo {
    display: inline-block;
    border-radius: 50%;
    background-color: #fff;
    transition: 0.4s; }
  #fund-factor-box .switch .sliders, #stock-factor-box .switch .sliders {
    background-color: #5d5c5d;
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: 0.4s;
    display: flex;
    align-items: center;
    border-radius: 50px; }
  #fund-factor-box .switch input, #stock-factor-box .switch input {
    display: none; }
  #fund-factor-box .switch input:not(:checked) + .sliders, #stock-factor-box .switch input:not(:checked) + .sliders {
    background-color: #5d5c5d !important; }
  #fund-factor-box #fund-factorBox-input, #fund-factor-box #stock-factorBox-input, #stock-factor-box #fund-factorBox-input, #stock-factor-box #stock-factorBox-input {
    width: 340px; }
  #fund-factor-box .gray-wrapper, #stock-factor-box .gray-wrapper {
    display: flex;
    background: rgba(32, 32, 32, 0.9);
    color: white;
    padding: 10px;
    border-radius: 4px 4px 0px 0px; }
    #fund-factor-box .gray-wrapper.white, #stock-factor-box .gray-wrapper.white {
      background: #002036; }
    #fund-factor-box .gray-wrapper:last-child, #stock-factor-box .gray-wrapper:last-child {
      border-radius: 0px 0px 4px 4px; }
    #fund-factor-box .gray-wrapper.no-border-radius, #stock-factor-box .gray-wrapper.no-border-radius {
      border-radius: 0px; }
    #fund-factor-box .gray-wrapper.no-padding, #stock-factor-box .gray-wrapper.no-padding {
      padding: 0px; }
    #fund-factor-box .gray-wrapper #stock-add-stocks-panel, #fund-factor-box .gray-wrapper #fund-add-stocks-panel, #stock-factor-box .gray-wrapper #stock-add-stocks-panel, #stock-factor-box .gray-wrapper #fund-add-stocks-panel {
      padding: 0px 10px; }
    #fund-factor-box .gray-wrapper.bottom-facs-cta, #stock-factor-box .gray-wrapper.bottom-facs-cta {
      border-top: 1px solid rgba(255, 255, 255, 0.1);
      padding: 10px;
      padding-top: 0;
      line-height: 40px;
      font-size: 20px;
      text-align: center; }
      #fund-factor-box .gray-wrapper.bottom-facs-cta a, #stock-factor-box .gray-wrapper.bottom-facs-cta a {
        width: 49%;
        text-decoration: none; }
      #fund-factor-box .gray-wrapper.bottom-facs-cta .cta-button, #stock-factor-box .gray-wrapper.bottom-facs-cta .cta-button {
        width: 100%;
        font-size: 18px;
        line-height: 24px;
        color: white;
        background-color: #1A3FD6;
        padding: 8px; }
        #fund-factor-box .gray-wrapper.bottom-facs-cta .cta-button:hover, #stock-factor-box .gray-wrapper.bottom-facs-cta .cta-button:hover {
          background-color: #4A7BC9; }
  #fund-factor-box #fund-accordion-container, #fund-factor-box #stock-accordion-container, #stock-factor-box #fund-accordion-container, #stock-factor-box #stock-accordion-container {
    position: fixed;
    width: 400px;
    top: 15px;
    right: 75px; }

#stock-factor-box-modal-cta, #fund-factor-box-modal-cta {
  width: 500px; }
  #stock-factor-box-modal-cta .bottom-facs-cta, #fund-factor-box-modal-cta .bottom-facs-cta {
    padding: 10px;
    padding-top: 0;
    text-align: center; }
    #stock-factor-box-modal-cta .bottom-facs-cta .title, #fund-factor-box-modal-cta .bottom-facs-cta .title {
      line-height: 24px;
      font-size: 24px; }
    #stock-factor-box-modal-cta .bottom-facs-cta .header, #fund-factor-box-modal-cta .bottom-facs-cta .header {
      margin-top: 10px;
      font-size: 18px; }
    #stock-factor-box-modal-cta .bottom-facs-cta a, #fund-factor-box-modal-cta .bottom-facs-cta a {
      width: 49%;
      text-decoration: none; }
    #stock-factor-box-modal-cta .bottom-facs-cta .cta-button, #fund-factor-box-modal-cta .bottom-facs-cta .cta-button {
      width: 100%;
      font-size: 18px;
      line-height: 24px;
      color: white;
      background-color: #1A3FD6;
      padding: 8px; }
      #stock-factor-box-modal-cta .bottom-facs-cta .cta-button:hover, #fund-factor-box-modal-cta .bottom-facs-cta .cta-button:hover {
        background-color: #4A7BC9; }
