.overlay-wrapper {
  position: absolute;
  z-index: 1000;
  right: -100vw;
  transition: right 2s;
  height: 100%;
  top: 0;
  padding: 25px; }
  .overlay-wrapper.overlay-open {
    right: 0; }
  .overlay-wrapper.white {
    background: white; }
  .overlay-wrapper.black {
    background: black;
    color: white; }
