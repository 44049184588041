body {
  margin: 0;
  height: 100vh; }

canvas {
  display: block; }

h1 {
  position: absolute;
  top: 2em;
  left: 2em;
  font-family: 'Montserrat';
  font-size: 6em;
  text-transform: uppercase;
  width: auto;
  line-height: .8em;
  border: 5px solid black;
  padding: .2em; }

#overlaycanvas, #touroverlaycanvas {
  position: absolute;
  top: 0px;
  left: 0px;
  pointer-events: none; }

#overlaydiv, #touroverlaydiv {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  pointer-events: none; }
  #overlaydiv span.label, #touroverlaydiv span.label {
    color: rgba(255, 255, 255, 0);
    font-size: 11pt;
    position: absolute;
    line-height: 14pt;
    pointer-events: none; }
    #overlaydiv span.label img, #touroverlaydiv span.label img {
      margin-top: -6px; }
    #overlaydiv span.label.negative img, #touroverlaydiv span.label.negative img {
      margin-top: 12%; }
    #overlaydiv span.label.positive img, #touroverlaydiv span.label.positive img {
      margin-top: -22%; }
    #overlaydiv span.label.noimage img, #touroverlaydiv span.label.noimage img {
      display: none; }

.overlay-title {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%);
  font-size: 2rem;
  margin-top: 12px;
  font-weight: 300;
  color: white; }
  .overlay-title.hidden {
    display: none; }

#contentoverlay, #tourcontentoverlay {
  position: absolute;
  right: -25%;
  top: 0;
  width: 25%;
  height: 100%;
  background: white;
  z-index: 1000; }
  #contentoverlay.overlay-shown, #tourcontentoverlay.overlay-shown {
    right: 0px; }
  #contentoverlay #step-content, #contentoverlay #tourstep-content, #tourcontentoverlay #step-content, #tourcontentoverlay #tourstep-content {
    padding: 40% 24px 0px 24px; }
    #contentoverlay #step-content img, #contentoverlay #tourstep-content img, #tourcontentoverlay #step-content img, #tourcontentoverlay #tourstep-content img {
      width: 80px;
      margin: -20px; }
    #contentoverlay #step-content div, #contentoverlay #tourstep-content div, #tourcontentoverlay #step-content div, #tourcontentoverlay #tourstep-content div {
      margin-top: 8px;
      font-size: 1.8rem; }
    #contentoverlay #step-content textarea, #contentoverlay #tourstep-content textarea, #tourcontentoverlay #step-content textarea, #tourcontentoverlay #tourstep-content textarea {
      width: 100%;
      height: 250px;
      font-size: 12px; }
  #contentoverlay .stepper-controls, #tourcontentoverlay .stepper-controls {
    display: flex;
    position: absolute;
    width: 100%;
    padding: 24px;
    bottom: 0px; }
    #contentoverlay .stepper-controls .steps, #tourcontentoverlay .stepper-controls .steps {
      padding-top: 12px; }

#wrapper, #tourwrapper {
  position: absolute;
  left: 0px;
  width: 100%; }
  #wrapper.overlay-shown, #tourwrapper.overlay-shown {
    left: -12.5%;
    top: 0; }

.change-to-2d {
  position: absolute;
  z-index: 1000;
  top: 80px;
  left: 64px; }
  .change-to-2d button {
    background-image: url(/assets/images/2d_white.png);
    background-size: 44%;
    background-position: center;
    background-repeat: no-repeat; }
    .change-to-2d button.clicked, .change-to-2d button.selected {
      background-color: white;
      background-image: url(/assets/images/2d_black.png);
      background-size: 44%;
      background-position: center;
      background-repeat: no-repeat; }
  .change-to-2d span {
    color: white;
    position: relative;
    top: -1em; }
  .change-to-2d.hidden {
    display: none; }

.change-to-3d {
  position: absolute;
  z-index: 1000;
  top: 16px;
  left: 64px; }
  .change-to-3d button {
    background-image: url(/assets/images/3d_white.png);
    background-size: 66%;
    background-position: center;
    background-repeat: no-repeat; }
    .change-to-3d button.clicked, .change-to-3d button.selected {
      background-color: white;
      background-image: url(/assets/images/3d_black.png);
      background-size: 66%;
      background-position: center;
      background-repeat: no-repeat; }
  .change-to-3d span {
    color: white;
    position: relative;
    top: -1em; }
  .change-to-3d.hidden {
    display: none; }

#back-to-3d-wrapper, #tourback-to-3d-wrapper {
  position: absolute;
  z-index: 1000;
  top: 144px;
  left: 64px;
  opacity: 1;
  transition: opacity 0.2s; }
  #back-to-3d-wrapper button, #tourback-to-3d-wrapper button {
    background-image: url(/assets/images/back_white.png);
    background-size: 55%;
    background-position: center;
    background-repeat: no-repeat; }
    #back-to-3d-wrapper button.clicked, #back-to-3d-wrapper button.selected, #tourback-to-3d-wrapper button.clicked, #tourback-to-3d-wrapper button.selected {
      background-color: white;
      background-image: url(/assets/images/back_black.png);
      background-size: 55%;
      background-position: center;
      background-repeat: no-repeat; }
  #back-to-3d-wrapper span, #tourback-to-3d-wrapper span {
    color: white;
    position: relative;
    top: -1em; }
  #back-to-3d-wrapper.hidden, #tourback-to-3d-wrapper.hidden {
    pointer-events: none;
    opacity: 0; }

.facs-sectors-page-wrapper.dark {
  color: white; }
  .facs-sectors-page-wrapper.dark a {
    color: white; }

.facs-sectors-page-header {
  display: flex;
  height: 15%; }

.facs-sectors-exposure-bar {
  padding: 0px;
  border-radius: 1.25rem;
  margin: auto;
  height: 15px;
  background-color: transparent;
  line-height: 0%;
  font-size: 0%;
  margin-left: 16px;
  margin-right: 16px; }

.facs-sectors-content-wrapper {
  display: flex; }
  .facs-sectors-content-wrapper div {
    padding: 0; }
  .facs-sectors-content-wrapper h2 {
    font-size: 3.5rem;
    font-weight: 500; }

.facs-sectors-content {
  font-size: 3.5rem;
  font-weight: 300;
  padding: 0px; }

.progress-bar-content {
  display: flex;
  width: 70%;
  margin-left: auto; }

.progress-bar-wrapper {
  margin-top: 14%; }
  .progress-bar-wrapper .lbl-line {
    opacity: 0;
    position: relative;
    top: 0px;
    right: 45px; }

.exposure-bar-label {
  text-align: center;
  margin-top: 2%;
  width: 70%;
  margin-left: auto; }

.progress-bar-text {
  margin: auto; }

.exposure-bar {
  width: 12.5%;
  display: inline-block;
  font-size: 0%;
  height: 100%; }

.base-bar {
  height: 15px;
  width: 0%; }
  .base-bar.animate-bar {
    width: 0%; }
  .base-bar.first {
    border-bottom-left-radius: 1.5rem;
    border-top-left-radius: 1.5rem; }
  .base-bar.last {
    border-bottom-right-radius: 1.5rem;
    border-top-right-radius: 1.5rem; }

.red1 {
  background-color: #e3091f; }

.red2 {
  background-color: #f83231; }

.red3 {
  background-color: #fb6521; }

.orange {
  background-color: #fea310; }

.yellow {
  background-color: #f0f220; }

.green1 {
  background-color: #befb96; }

.green2 {
  background-color: #20fa61; }

.green3 {
  background-color: #0fd34a; }

@media (max-width: 1600px) {
  .facs-sectors-content {
    font-size: 2.5rem; }
  .change-to-2d {
    top: 64px; }
    .change-to-2d span {
      font-size: 0.8em; }
  .change-to-3d span {
    font-size: 0.8em; }
  #back-to-3d-wrapper, #tourback-to-3d-wrapper {
    top: 112px; }
    #back-to-3d-wrapper span, #tourback-to-3d-wrapper span {
      font-size: 0.8em; } }

@media (max-width: 1370px) {
  .facs-sectors-content-wrapper h2 {
    font-size: 3rem;
    font-weight: 500; } }

@media (max-width: 992px) {
  .facs-sectors-content {
    font-size: 1.5rem; }
  .progress-bar-content {
    margin: auto;
    width: 70%; }
  .exposure-bar-label {
    margin: auto;
    width: 70%; } }

#visualization-poc .page-wrapper.facs-sectors-page-wrapper {
  transition: none; }

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  #overlaydiv, #touroverlaydiv {
    pointer-events: none; }
    #overlaydiv span.label, #touroverlaydiv span.label {
      font-size: 10pt;
      position: absolute;
      line-height: 12pt;
      pointer-events: none; }
  #contentoverlay, #tourcontentoverlay {
    right: -28%;
    width: 28%;
    height: 100%; }
    #contentoverlay #step-content div, #contentoverlay #tourstep-content div, #tourcontentoverlay #step-content div, #tourcontentoverlay #tourstep-content div {
      margin-top: 8px;
      font-size: 1.4rem; }
  .change-to-3d span {
    top: unset; }
  .change-to-2d span {
    top: unset; } }

.tooltip-3d {
  position: fixed;
  padding: 15px;
  border-radius: 25px;
  color: white;
  background: rgba(63, 63, 63, 0.75);
  opacity: 0;
  transition: opacity 2s;
  z-index: 1000;
  pointer-events: none; }
  .tooltip-3d img {
    width: 50px;
    position: absolute; }
  .tooltip-3d#camera-tooltip img, .tooltip-3d #tourcamera-tooltip img {
    position: relative; }

#col-tooltip img, #tourcol-tooltip img {
  left: -34px; }
