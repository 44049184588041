#pre-text-content {
  font-size: 3.25rem;
  font-weight: 300; }

.pre-text-header {
  display: flex;
  height: 20%; }

.pre-text-content-wrapper {
  padding: 0; }
  .pre-text-content-wrapper h2 {
    font-size: 3.5rem;
    font-weight: 400; }

@media (max-width: 1023px) {
  #pre-text-content {
    font-size: 1.75rem; }
  .pre-text-content-wrapper h2 {
    font-size: 2.5rem; } }

@media (min-width: 1200px) and (max-width: 1600px) {
  #pre-text-content {
    font-size: 3rem; }
  .pre-text-content-wrapper h2 {
    font-size: 3.25rem; }
  .pre-text-header {
    height: 15%; } }

@media (min-width: 1200px) and (max-width: 1366px) {
  #pre-text-content {
    font-size: 2.75rem; }
  .pre-text-content-wrapper h2 {
    font-size: 3rem; }
  .pre-text-header {
    height: 15%; } }

@media (min-width: 1024px) and (max-width: 1200px) {
  #pre-text-content {
    font-size: 2.25rem; }
  .pre-text-content-wrapper h2 {
    font-size: 3rem; } }
