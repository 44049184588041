#faang-chart {
  padding: 20px;
  color: white; }
  #faang-chart #faang-select, #faang-chart #faang-companies {
    width: 300px;
    text-overflow: ellipsis;
    border: none; }
  #faang-chart .overlay-tooltip {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 15px;
    border-radius: 25px;
    color: white;
    background: rgba(63, 63, 63, 0.75);
    opacity: 0;
    z-index: 1000;
    user-select: none;
    pointer-events: none; }
  #faang-chart .title-container {
    display: flex;
    margin-top: 40px;
    margin-bottom: 50px; }
    #faang-chart .title-container .separator {
      flex: 1 1 auto; }
    #faang-chart .title-container .title, #faang-chart .title-container .stocktitle {
      white-space: nowrap;
      text-align: center; }
    #faang-chart .title-container .title {
      padding-left: 25px; }
    #faang-chart .title-container .stocktitle {
      position: inherit;
      padding-right: 80px; }
    #faang-chart .title-container .factortitle p {
      position: absolute;
      left: 50%;
      transform: translate(-50%);
      font-size: 1.6rem; }
  #faang-chart .scene-container {
    display: flex;
    margin-top: 0px; }
    #faang-chart .scene-container #labels #faang-switches {
      width: max-content;
      padding-top: 20px; }
    #faang-chart .scene-container .factor {
      margin-left: 20px; }
    #faang-chart .scene-container .chart {
      display: inline-block;
      height: 100%;
      position: absolute; }
    #faang-chart .scene-container .stock, #faang-chart .scene-container .stocktitle {
      position: absolute;
      right: 0px;
      padding-right: 80px; }
    #faang-chart .scene-container .title, #faang-chart .scene-container .stocktitle {
      white-space: nowrap;
      text-align: center; }
    #faang-chart .scene-container .stocktitle {
      position: inherit;
      padding-right: 80px; }
    #faang-chart .scene-container .circle-label {
      text-align: center;
      width: max-content;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      margin-bottom: 20px; }
      #faang-chart .scene-container .circle-label .circle-bg {
        display: block;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        background-size: 90%;
        background-position: center; }
      #faang-chart .scene-container .circle-label .circle-md {
        display: block;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        background-size: 120%;
        background-position: center; }
      #faang-chart .scene-container .circle-label .circle-sm {
        display: inline-block;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        position: relative;
        left: 20px;
        transform: translateX(-20px);
        background-size: 140%;
        background-position: center; }
  @media (max-width: 1600px) {
    #faang-chart .scene-container .chart {
      width: 88%; }
    #faang-chart .title-container {
      margin-top: 20px; }
      #faang-chart .title-container .factortitle p {
        font-size: 1.4rem; } }
  @media (max-width: 1400px) {
    #faang-chart .scene-container .chart {
      width: 94%; }
    #faang-chart .title-container {
      margin-top: 10px; }
      #faang-chart .title-container .factortitle p {
        font-size: 1.2rem; } }
  @media (max-width: 600px) {
    #faang-chart .scene-container {
      grid-template-columns: minmax(80px, 160px); }
      #faang-chart .scene-container .stock {
        grid-column-start: 2;
        grid-column-end: 2;
        position: absolute;
        right: 0px;
        top: 100px; }
      #faang-chart .scene-container .chart {
        grid-column-start: 1;
        grid-column-end: 1;
        left: 80px; }
      #faang-chart .scene-container .stocktitle, #faang-chart .scene-container .factortitle {
        display: none; } }
  #faang-chart #faang-company-input {
    width: 340px; }
  #faang-chart .close {
    margin-left: 12px;
    position: absolute;
    float: left;
    font-size: 28px;
    font-weight: bold;
    color: gray; }
  #faang-chart .close:hover,
  #faang-chart .close:focus {
    text-decoration: none;
    cursor: pointer; }
  #faang-chart .btn-info {
    line-height: 15px;
    border: 2px solid rgba(91, 192, 222, 0.75);
    border-radius: 40px;
    transition: all 0.3s ease 0s; }
  #faang-chart .btn-info:hover {
    border: 2px solid rgba(91, 192, 222, 0.75); }
  #faang-chart .gray-wrapper {
    display: flex;
    background: rgba(32, 32, 32, 0.9);
    color: white;
    padding: 10px;
    border-radius: 0px 4px 4px 4px; }
    #faang-chart .gray-wrapper.white {
      background: rgba(196, 196, 196, 0.95);
      color: black; }
  #faang-chart #accordion-container {
    position: fixed;
    min-width: 400px;
    top: 50px;
    right: 75px; }
