/* Ripple effect */
.button-fill {
  background-position: center;
  transition: background 1s; }
  .button-fill.clicked {
    background: white radial-gradient(circle, black 1%, white 1%) center/15000%; }

.button-fill:active {
  background-color: white;
  background-size: 100%;
  transition: background 0s; }

/* Button style */
button.button-fill {
  cursor: pointer;
  color: black;
  background-color: white;
  outline: none; }
  button.button-fill span {
    color: white;
    mix-blend-mode: difference; }

/* fallback */
.button-fill-fallback {
  border: 2px solid black;
  display: block;
  cursor: pointer;
  background: white;
  position: relative;
  overflow: hidden; }
  .button-fill-fallback:focus {
    outline-width: 0; }
  .button-fill-fallback:focus:not(:active) {
    animation: black 0.4s forwards; }
    .button-fill-fallback:focus:not(:active) .inner {
      animation: ripple 0.4s forwards; }
  .button-fill-fallback .inner {
    overflow: hidden;
    position: absolute;
    top: -50%;
    width: 0%;
    background: black;
    left: 50%;
    border-radius: 100%;
    display: block;
    height: 200%;
    color: white;
    box-sizing: border-box;
    white-space: nowrap;
    z-index: 0; }
    .button-fill-fallback .inner span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }

@keyframes black {
  0% {
    background: white; }
  98% {
    background: white; }
  100% {
    background: black; } }

@keyframes ripple {
  0% {
    width: 0%;
    left: 50%; }
  100% {
    width: 102%;
    left: -1%; } }
